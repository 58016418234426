import mine from 'assets/minesweeper/mine-icon.png';
import scatter from 'assets/scatter.png';
import notepad from 'assets/windowsIcons/327(16x16).png';
import notepadLarge from 'assets/windowsIcons/327(32x32).png';
import computer from 'assets/windowsIcons/676(16x16).png';
import computerLarge from 'assets/windowsIcons/676(32x32).png';
import paint from 'assets/windowsIcons/680(16x16).png';
import paintLarge from 'assets/windowsIcons/680(32x32).png';
import error from 'assets/windowsIcons/897(16x16).png';
import winamp from 'assets/windowsIcons/winamp.png';
import Affiliate from './Affiliate';
import ErrorBox from './ErrorBox';
import Minesweeper from './Minesweeper';
import Mint from './Mint';
import Bin from './Bin'
import MyComputer from './MyComputer';
import Notepad from './Notepad';
import Paint from './Paint';
import bin from 'assets/windowsIcons/bin.png';
import FaqsBox from './FaqsBox'
import Redeem from './Redeem'
import info from 'assets/info1.png';
import redeemicon from 'assets/redeem.png'
import VRMguide from './VRMguide';

import Music from './Music';


const gen = () => {
  let id = -1;
  return () => {
    id += 1;
    return id;
  };
};
const genId = gen();
const genIndex = gen();
export const defaultAppState = [
  // {
  //   component: Minesweeper,
  //   header: {
  //     title: 'Minesweeper',
  //     icon: mine,
  //   },
  //   defaultSize: {
  //     width: 0,
  //     height: 0,
  //   },
  //   defaultOffset: {
  //     x: window.innerWidth < 800 ? 100 : 180,
  //     y: window.innerWidth < 800 ? 200 : 550,
  //   },
  //   resizable: false,
  //   minimized: false,
  //   maximized: false,
  //   id: genId(),
  //   zIndex: genIndex(),
  // },
  // {
  //   component: MyComputer,
  //   header: {
  //     title: 'My Computer',
  //     icon: computer,
  //   },
  //   defaultSize: {
  //     width: 660,
  //     height: 500,
  //   },
  //   defaultOffset: {
  //     x: 150,
  //     y: 40,
  //   },
  //   resizable: true,
  //   minimized: false,
  //   maximized: window.innerWidth < 800,
  //   id: genId(),
  //   zIndex: genIndex(),
  // },
  {
    component: Music,
    header: {
      title: 'Music',
      icon: winamp,
      invisible: true,
    },
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: window.innerWidth < 800 ? 50 : 600,
      y: 60,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    id: genId(),
    zIndex: genIndex(),
  },
  {
    component: Affiliate,
    header: {
      title: 'Affiliate Program',
      icon: scatter,
    },
    defaultSize: {
      width: 660,
      height: 600,
    },
    defaultOffset: {
      x: 100,
      y: 20,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    id: genId(),
    zIndex: genIndex(),
  },
  {
    component: Mint,
    header: {
      title: 'Mint Now',
      icon: scatter,
    },
    defaultSize: {
      width: 580,
      height: 500,
    },
    defaultOffset: {
      x: 550,
      y: 100,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    id: genId(),
    zIndex: genIndex(),
  },

];

export const defaultIconState = [
  {
    id: -1,
    icon: redeemicon,
    title: 'Redeem',
    component: Redeem,
    isFocus: false,
  },
  {
    id: 0,
    icon: info,
    title: 'VRM Guide',
    component: VRMguide,
    isFocus: false,
  },
  {
    id: 1,
    icon: mine,
    title: 'Minesweeper',
    component: Minesweeper,
    isFocus: false,
  },
  {
    id: 2,
    icon: computerLarge,
    title: 'My Computer',
    component: MyComputer,
    isFocus: false,
  },
  {
    id: 4,
    icon: scatter,
    title: 'Mint Now',
    component: Mint,
    isFocus: false,
  },
  {
    id: 7,
    icon: scatter,
    title: 'Affiliate Program',
    component: Affiliate,
    isFocus: false,
  },
  {
    id: 6,
    icon: winamp,
    title: 'Music',
    component: Music,
    isFocus: false,
  },
  {
    id: 8,
    icon: bin,
    title: 'Bin',
    component: Bin,
    isFocus: false,
  },
  {
    id: 9,
    icon: info,
    title: 'FAQs',
    component: FaqsBox,
    isFocus: false,
  },
  {
    id: 10,
    icon: paintLarge,
    title: 'Paint',
    component: Paint,
    isFocus: false,
  },
  {
    id: 11,
    icon: notepadLarge,
    title: 'Notepad',
    component: Notepad,
    isFocus: false,
  },
  
];

export const appSettings = {
  Minesweeper: {
    header: {
      icon: mine,
      title: 'Minesweeper',
    },
    component: Minesweeper,
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 190,
      y: 60,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Error: {
    header: {
      icon: error,
      title: 'C:\\',
      buttons: ['close'],
      noFooterWindow: true,
    },
    component: ErrorBox,
    defaultSize: {
      width: 380,
      height: 0,
    },
    defaultOffset: {
      x: window.innerWidth / 2 - 190,
      y: window.innerHeight / 2 - 60,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  'My Computer': {
    header: {
      icon: computer,
      title: 'My Computer',
    },
    component: MyComputer,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 260,
      y: 50,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: false,
  },
  Notepad: {
    header: {
      icon: notepad,
      title: 'Untitled - Notepad',
    },
    component: Notepad,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Mint: {
    header: {
      icon: scatter,
      title: 'Mint Now',
    },
    component: Mint,
    defaultSize: {
      width: 580,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Affiliate: {
    header: {
      icon: scatter,
      title: 'Affiliate Program',
    },
    component: Affiliate,
    defaultSize: {
      width: 660,
      height: 600,
    },
    defaultOffset: {
      x: 60,
      y: 30,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Paint: {
    header: {
      icon: paint,
      title: 'Untitled - Paint',
    },
    component: Paint,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 280,
      y: 70,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Music: {
    component: Music,
    header: {
      title: 'Music',
      icon: winamp,
      invisible: true,
    },
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    id: genId(),
    zIndex: genIndex(),
  },
  Bin: {
    header: {
      icon: bin,
      title: 'Bin',
    },
    component: Bin,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  FaqsBox: {
    header: {
      icon: info,
      title: 'FAQs',
    },
    component: FaqsBox,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Redeem: {
    header: {
      icon: redeemicon,
      title: 'Redeem',
    },
    component: Redeem,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  VRMguide: {
    header: {
      icon: info,
      title: 'VRM Guide',
    },
    component: VRMguide,
    defaultSize: {
      width: 1060,
      height: 360,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },

};

export { ErrorBox, Minesweeper, MyComputer, Notepad, Music, Bin, FaqsBox, Redeem };
