import React, { useState } from 'react';
import styled from 'styled-components';

// import { WindowDropDowns } from 'components';
// import dropDownData from './dropDownData';

export default function Bin({ onClose }) {
  // const [docText, setDocText] = useState('');
  // const [wordWrap, setWordWrap] = useState(false);
  const [copiedAddress, setCopiedAddress] = useState('');

  // function onClickOptionItem(item) {
  //   switch (item) {
  //     case 'Exit':
  //       onClose();
  //       break;
  //     case 'Word Wrap':
  //       setWordWrap(!wordWrap);
  //       break;
  //     case 'Time/Date': {
  //       const date = new Date();
  //       setDocText(
  //         `${docText}${date.toLocaleTimeString()} ${date.toLocaleDateString()}`,
  //       );
  //       break;
  //     }
  //     default:
  //   }
  // }
  // function onTextAreaKeyDown(e) {
  //   // handle tabs in text area
  //   if (e.which === 9) {
  //     e.preventDefault();
  //     e.persist();
  //     var start = e.target.selectionStart;
  //     var end = e.target.selectionEnd;
  //     setDocText(`${docText.substring(0, start)}\t${docText.substring(end)}`);

  //     // asynchronously update textarea selection to include tab
  //     // workaround due to https://github.com/facebook/react/issues/14174
  //     requestAnimationFrame(() => {
  //       e.target.selectionStart = start + 1;
  //       e.target.selectionEnd = start + 1;
  //     });
    // }
  // }

  return (
    <Div>
      {/* <section className="np__toolbar">
        <WindowDropDowns items={dropDownData} onClickItem={onClickOptionItem} />
      </section> */}
      {/* <StyledTextarea
        wordWrap={wordWrap}
        value={docText}
        onChange={e => setDocText(e.target.value)}
        onKeyDown={onTextAreaKeyDown}
        spellCheck={false}
      /> */}
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>
      <div className='text-center'>
      This bin is for throwing out old, trash NFTs, recyclables will be dealt with accordingly!
      </div>
      <span className='flex'>
      <div className='text-center'>
      0x2Cf8704A28a2Cc0E9Cb7436Baf11aDE66c29755C 
      </div>
      <div className="bg-quaternary px-5 text-center ">
        <div className="text-sm">
          <div
            className="font-mono text-sm group pb-6 "
            onClick={() => {
              setCopiedAddress(true);
              navigator.clipboard.writeText(
                `0x2Cf8704A28a2Cc0E9Cb7436Baf11aDE66c29755C`
              );

              if (!copiedAddress) {
                setTimeout(() => {
                  setCopiedAddress(false);
                }, 3000);
              }
            }}
          >
            <div className="flex align-center mt-0">
              <div className="font-sans font-bold pr-1 text-black group-hover:text-gray-600 cursor-pointer transition-colors" >
                {copiedAddress ? 'Copied!' : 'Copy Link'}
              </div>
              <div className="h-3 w-3 text-black group-hover:text-gray-600 cursor-pointer transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div> 
      </span>
      </section>
    </Div>
  );
}

const Div = styled.div`
  height: 100%;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }
`;

// const StyledTextarea = styled.textarea`
//   flex: auto;
//   outline: none;
//   font-family: 'Lucida Console', monospace;
//   font-size: 13px;
//   line-height: 14px;
//   resize: none;
//   padding: 2px;
//   ${props => (props.wordWrap ? '' : 'white-space: nowrap; overflow-x: scroll;')}
//   overflow-y: scroll;
//   border: 1px solid #96abff;
// `;
