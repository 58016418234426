// SEPOLIA
// export const COLLECTION_ADDRESS = '0xE84867497b679C70582D1b97002627297d5C7707';
// export const API_URL = process.env.API_URL || 'https://api-testing.scatter.art';
// export const ETHERSCAN_DOMAIN = 'https://sepolia.etherscan.io';

// Mainnet
export const COLLECTION_ADDRESS = "0x112423592Fc313EF04a1C147a7AE3dadb99D7cdd";
export const API_URL = "https://api.scatter.art";
export const ETHERSCAN_DOMAIN = "https://etherscan.io";

export const TOAST_CONFIG = {
  theme: "light",
  // style: {
  //   backgroundColor: '#0C0C0C',
  //   fontWeight: 500,
  // },
  // progressStyle: { backgroundColor: '#FD6E2C' },
  autoClose: 5000,
};

export const BASE_URL = "https://y2ktestapi.vercel.app";
