import { fetchReferrals } from 'components/clientApi';
import { ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useTable } from 'react-table';

export function shortenAddress(address) {
  if (!address || !address.length) {
    return '';
  }
  return (
    address.substring(0, 6) + '...' + address.substring(address.length - 4)
  );
}

export function shortenEns(address) {
  if (!address || !address.length) {
    return '';
  }
  if (address.length < 22) {
    return address;
  }
  return (
    address.substring(0, 18) + '..' + address.substring(address.length - 4)
  );
}

function ReferralsTable({ referrals }) {
  const affiliateRows = referrals.map(referral => {
    return {
      ...referral,
      transactionHash: (
        <div className="inline-flex align-center justify-center">
          <div>
            <a
              href={`https://etherscan.io/tx/${referral.transactionHash}`}
              target="_blank"
              rel="noreferrer"
              className="text-tan hover:text-blue-600 font-mono"
            >
              {shortenAddress(referral.transactionHash)}
            </a>
          </div>
          <div className="ml-1 h-4 w-4 text-tertiary">
            <ArrowTopRightOnSquareIcon />
          </div>
        </div>
      ),
      minter: (
        <a href={`/user/${referral.minter}`}>
          <div className="text-tan hover:text-blue-600 font-mono">
            {referral.minterEns || shortenAddress(referral.minter)}
          </div>
        </a>
      ),
      date: (
        <span className="text-sm text-tan">
          {new Date(referral.date).toLocaleString()}
        </span>
      ),
      txnValue: (
        <span className="font-mono text-right text-tan">
          {referral.txnValue}
          <span className="text-slate-400 ml-1">
            {ethers.constants.EtherSymbol}
          </span>
        </span>
      ),
      referralAmountEth: (
        <span className="font-mono text-right ">
          {referral.referralAmountEth}
          <span className="text-tan ml-1">
            {ethers.constants.EtherSymbol}
          </span>
        </span>
      ),
    };
  });

  const data = useMemo(() => affiliateRows, [affiliateRows]);

  const columns = useMemo(
    () => [
      {
        Header: 'Transaction Hash',
        accessor: 'transactionHash',
      },
      {
        Header: 'Minter',
        accessor: 'minter',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Transaction Value',
        Footer: info => {
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => {
                console.log({ row });
                return Number(row.values.txnValue.props.children[0]) + sum;
              }, 0),
            [info.rows],
          );

          return (
            <span
              className={`${
                referrals.length ? 'text-tan' : 'text-slate-400'
              }  font-mono text-sm`}
            >
              <span className="mr-1">{total}</span>
              {ethers.constants.EtherSymbol}
            </span>
          );
        },
        accessor: 'txnValue',
      },
      {
        Header: 'Referral Earned',
        Footer: info => {
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => {
                return ethers.BigNumber.from(
                  row.original.referralAmountWei,
                ).add(sum);
              }, ethers.BigNumber.from(0)),
            [info.rows],
          );

          return (
            <span
              className={`${
                referrals.length ? 'text-tan' : 'text-slate-400'
              }  font-mono text-sm`}
            >
              <span className="mr-1">{ethers.utils.formatEther(total)}</span>
              {ethers.constants.EtherSymbol}
            </span>
          );
        },
        accessor: 'referralAmountEth',
      },
    ],
    [referrals],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({ columns, data });

  return (
    <>
      <div className="px-6 pt-6 pb-5 bg-tertiary text-lg font-semibold text-tan " style={{
                fontFamily: 'RuneScapeRegular'}}>
        Your Referral Activity
      </div>
      <table
        {...getTableProps()}
        className="bg-tertiary p-10 w-full text-sm text-slate-250"
        style={{
          fontFamily: 'RuneScapeRegular'}}
      >
        {referrals.length ? (
          <thead>
            {headerGroups.map((headerGroup, i) => {
              return (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={i}
                  className="text-sm text-tan "
                  style={{
                    fontFamily: 'RuneScapeRegular'}}
                >
                  {headerGroup.headers.map(column => (
                    <th
                      key={column.id}
                      {...column.getHeaderProps()}
                      className={`${
                        column.id === 'referralAmountEth' ||
                        column.id === 'txnValue'
                          ? 'pr-6 text-right'
                          : 'pl-6 text-left'
                      } py-2 bg-secondary font-normal tracking-wide text-tan`}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
        ) : null}
        {referrals.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className={`px-4 ${
                    i % 2 === 0 ? 'bg-tertiary' : 'bg-secondary'
                  }`} style={{
                    fontFamily: 'RuneScapeRegular'}}
                >
                  {row.cells.map(cell => {
                    // console.log({ cell });
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cell.column.id}
                        className={`p-2 text-tan${
                          cell.column.id === 'referralAmountEth' ||
                          cell.column.id === 'txnValue'
                            ? 'text-right px-6'
                            : 'px-6'
                        }`} style={{
                          fontFamily: 'RuneScapeRegular'}}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="text-center pt-8 pb-2 text-sm text-tan " colSpan={5} style={{
                fontFamily: 'RuneScapeRegular'}}>
                No referrals yet
              </td>
            </tr>
          </tbody>
        )}
        <tfoot>
          {footerGroups.map((group, i) => (
            <tr {...group.getFooterGroupProps()} key={i}>
              {group.headers.map(column => (
                <td
                  {...column.getFooterProps()}
                  key={column.id}
                  className="text-right pt-3 pb-1 px-6"
                  style={{
                    fontFamily: 'RuneScapeRegular'}}
                >
                  {column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <div className="h-2 bg-tertiary ">&nbsp;</div>
    </>
  );
}

export default function ReferralsSection({ pid, userAddress }) {
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    async function getReferralActivity() {
      if (!userAddress) {
        return;
      }
      if (!pid) {
        return;
      }

      const referralsRes = await fetchReferrals([
        'address',
        ethers.utils.getAddress(userAddress),
        'collectionAddress',
        ethers.utils.getAddress(pid),
      ]);

      if (referralsRes) {
        setReferrals(referralsRes);
      }
    }

    try {
      getReferralActivity();
    } catch (err) {
      console.error(err);
    }
  }, [pid, userAddress]);

  return <ReferralsTable referrals={referrals} />;
}
