import {
  addSingleAffiliate,
  getInviteListsTruncated,
  isAffiliate,
} from 'components/clientApi';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ethers } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// import { decodeError } from './MintTabV40';
import { useAccount } from 'wagmi';
import { generateUsername, isUsernameTaken } from '../../../components/utils';
import {
  getUsers,
  addUser,
  updateUser,
  getUser,
} from '../../../components/usernameHelper';
import compare_users from '../../../assets/compare_users.png';
import coins from '../../../assets/coins.png';
// import {
//   LinkIcon,
//   ReceiptPercentIcon,
//   ScaleIcon,
// } from '@heroicons/react/24/outline';
import { TOAST_CONFIG, COLLECTION_ADDRESS } from 'components/constants';
import { ErrorBoundary } from 'react-error-boundary';
import LeaderboardSection from './LeaderboardSection';
import ReferralsSection from './ReferralsSection';
// import { handleError } from "lib/functions/utilities";
import {
  getNftContract,
  useEthersProvider,
  useEthersSigner,
} from 'components/utils';

const AFFILIATE_PERCENT = 1500;

export function getLink(code) {
  return `https://y2kids.netlify.app/?ref=${code}`;
}

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong with the affiliates window</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

export function AffiliatesTab({ pid, collection }) {
  const { address: userAddress, isConnected } = useAccount();

  const [affiliateStatus, setAffiliateStatus] = useState({
    isAffiliate: false,
    affiliateId: '',
  });

  const [tokenBalances, setTokenBalances] = useState([]);

  const [affiliateBalance, setAffiliateBalance] = useState();

  const [nftContract, setNftContract] = useState();

  const signer = useEthersSigner();
  const provider = useEthersProvider();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const { address, isConnected } = useAccount()
  // console.log({ affiliatesTable: affiliates });
  const pkey = isConnected ? userAddress : '';

  const fetchUsers = async () => {
    try {
      const users = await getUsers();
      setUsers(users);
      console.log(users);
      // Do something with the users data
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false); // Set loading to false once users are fetched or an error occurs
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [isConnected]);

  const publicKeyExists = users?.some(obj =>
    Object.keys(obj)?.some(key => obj[key] === pkey),
  );
  const desiredObject = users?.find(obj => obj.pubKey === pkey);
  const userid = desiredObject?.username;
  const dbUserId = desiredObject?._id;
  // const addMutation = useMutation(addUser, {
  //   onSuccess : () => {
  //              queryClient.prefetchQuery('users', getUsers)
  //          }
  //      })
  //  const addUserToApi = async () => {
  //    try {
  //            const userData = {
  //              "username":generateUsername(users),
  //              "pubKey":userAddress,
  //          };
  //          const result = await addUser(userData);
  //          console.log(result);
  //          // Handle the result (response from the server) as needed
  //      } catch (error) {
  //          console.error('Error adding user:', error);
  //      }
  //  };

  useEffect(() => {
    console.log('isConnected:' + isConnected + 'loading:' + loading);

    const addUserToApi = async () => {
      try {
        const userData = {
          username: generateUsername(users),
          pubKey: userAddress,
        };
        const result = await addUser(userData);
        console.log(result);
        // Handle the result (response from the server) as needed
      } catch (error) {
        console.error('Error adding user:', error);
      } finally {
        window.location.reload();
      }
    };
    const handleSubmit = e => {
      console.log('adding user....');
      addUserToApi();
    };
    if (isConnected && !loading) {
      if (publicKeyExists === false) {
        handleSubmit();
      }
    }
  }, [isConnected, loading, userAddress, publicKeyExists, users]);

  // console.log(address, connector, isConnected)

  useEffect(() => {
    (async function initNftContracts() {
      try {
        if (!signer || !COLLECTION_ADDRESS) {
          return;
        }

        const nftContract = getNftContract({
          collectionAddress: COLLECTION_ADDRESS,
          provider,
          signer,
        });

        setNftContract(nftContract);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [signer, provider]);

  useEffect(() => {
    async function getAffiliateStatus() {
      if (!userAddress) {
        return;
      }

      if (!COLLECTION_ADDRESS) {
        return;
      }

      const affiliateDataRes = await isAffiliate([
        'address',
        userAddress,
        'collectionAddress',
        COLLECTION_ADDRESS,
      ]);

      if (affiliateDataRes) {
        const { isAffiliate, affiliateId } = affiliateDataRes;
        console.log({ isAffiliate, affiliateId });
        setAffiliateStatus({ isAffiliate, affiliateId });
      }
    }

    try {
      getAffiliateStatus();
    } catch (err) {
      console.error(err);
    }
  }, [userAddress, setAffiliateStatus]);

  const _fetchTokenBalances = useCallback(async () => {
    if (!nftContract) {
      return;
    }

    if (!COLLECTION_ADDRESS) {
      return;
    }

    let allListsRes = await getInviteListsTruncated(COLLECTION_ADDRESS);

    if (!allListsRes) {
      return;
    }

    console.log({ allListsRes });

    const uniqueErc20Tokens = [];

    const erc20Tokens = allListsRes
      .filter(list => list.currencyAddress !== ethers.constants.AddressZero)
      .filter(list => {
        const address = list.currencyAddress
          ? ethers.utils.getAddress(list.currencyAddress)
          : '';

        if (uniqueErc20Tokens.includes(address)) {
          return false;
        } else {
          uniqueErc20Tokens.push(address);
          return true;
        }
      })
      .map(list => {
        return {
          currencyAddress: ethers.utils.getAddress(list.currencyAddress || ''),
          symbol: list.symbol || '',
        };
      });

    if (!erc20Tokens.length) {
      return;
    }

    let _tokenBalances = [];

    for (const { currencyAddress, symbol } of erc20Tokens) {
      const tokenBalances = await nftContract.affiliateBalanceToken(
        userAddress,
        currencyAddress,
      );

      // console.log({ tokenBalances });
      _tokenBalances.push({
        symbol,
        currencyAddress,
        balance: ethers.utils.formatEther(tokenBalances) || '0',
      });
    }

    console.log({ _tokenBalances });
    setTokenBalances(_tokenBalances);
  }, [nftContract, userAddress]);

  useEffect(() => {
    async function fetchBalance() {
      if (!userAddress || !nftContract) {
        return;
      }

      const affiliateBalanceRes = await nftContract.affiliateBalance(
        userAddress,
      );
      console.log({ affiliateBalanceRes });

      setAffiliateBalance(Number(affiliateBalanceRes));

      _fetchTokenBalances();
    }

    try {
      fetchBalance();
    } catch (err) {
      console.error(err);
    }
  }, [userAddress, pid, nftContract, _fetchTokenBalances]);

  if (!collection) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <div className="mt-8 w-full ">
          <div className="mx-auto">
            <div className="mt-2  grid gap-3 grid-cols-2">
              <div
                className="col-span-2 md:col-span-1"
                style={{ marginInline: 'auto' }}
              >
                <PercentCard affiliatePercent={AFFILIATE_PERCENT} />
              </div>

              <BalanceCard
                affiliateBalance={affiliateBalance}
                tokenBalances={tokenBalances}
                nftContract={nftContract}
                _fetchTokenBalances={_fetchTokenBalances}
              />
              {affiliateStatus.isAffiliate ? (
                <LinkCard
                  affiliateStatus={affiliateStatus}
                  userid={userid}
                  dbUserId={dbUserId}
                  users={users}
                />
              ) : (
                <SignUpCard
                  userAddress={userAddress}
                  setAffiliateStatus={setAffiliateStatus}
                  affiliatePercent={AFFILIATE_PERCENT}
                  collection={collection}
                  dbUserId={dbUserId}
                />
              )}
            </div>
          </div>

          <div className="mt-8 mx-auto w-full">
            {affiliateStatus.isAffiliate ? (
              <ReferralsSection pid={pid} userAddress={userAddress} />
            ) : null}
            <div className="mt-8">
              <LeaderboardSection pid={pid} users={users} loading={loading} />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8 w-full ">
          <div className="mx-auto">
            <div
              className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
              style={{ marginInline: 'auto' }}
            >
              <PercentCard affiliatePercent={AFFILIATE_PERCENT} />
              <BalanceCard
                affiliateBalance={affiliateBalance}
                tokenBalances={tokenBalances}
                nftContract={nftContract}
                _fetchTokenBalances={_fetchTokenBalances}
              />
              {affiliateStatus.isAffiliate ? (
                <LinkCard
                  affiliateStatus={affiliateStatus}
                  userid={userid}
                  dbUserId={dbUserId}
                  users={users}
                />
              ) : (
                <SignUpCard
                  userAddress={userAddress}
                  setAffiliateStatus={setAffiliateStatus}
                  affiliatePercent={AFFILIATE_PERCENT}
                  collection={collection}
                  dbUserId={dbUserId}
                />
              )}
            </div>
          </div>

          <div className="mt-8 mx-auto w-full">
            {affiliateStatus.isAffiliate ? (
              <ReferralsSection pid={pid} userAddress={userAddress} />
            ) : null}
            <div className="mt-8">
              <LeaderboardSection pid={pid} users={users} loading={loading} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const PercentCard = ({ affiliatePercent }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={details => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      {isMobile ? (
        <div
          className="shadow"
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '11rem',
          }}
        >
          <section
            className="overflow-hidden flex flex-col"
            style={{ paddingInline: '0.7rem', paddingTop: '0.5rem' }}
          >
            <div className="">
              <div className=" ">
                <div
                  className=" font-bold text-tan  text-center"
                  style={{
                    fontFamily: 'RuneScapeRegular',
                    fontSize: '1rem',
                  }}
                >
                  Affiliate Percent
                </div>
                <div></div>
                <div
                  className={`${
                    (affiliatePercent || 0) > 0 ? 'text-gold' : 'text-gold'
                  }  text-6xl font-medium flex-1`}
                  style={{
                    fontFamily: 'RuneScapeRegular',
                  }}
                >
                  {affiliatePercent
                    ? (affiliatePercent || 0) / 100 + '%'
                    : '??'}
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 ">
                <div
                  className="text-sm text-tan"
                  style={{ fontFamily: 'RuneScapeRegular' }}
                >
                  {affiliatePercent
                    ? `Affiliates earn ${(affiliatePercent || 0) /
                        100}% of every mint referred.`
                    : 'Please connect wallet to see affiliate %'}
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div
          className=" shadow  "
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '13rem',
          }}
        >
          <section className="overflow-hidden p-3 flex flex-col">
            <div className="">
              <div className=" ">
                <div
                  className=" font-bold text-tan truncate tracking-wide text-xl text-center"
                  style={{
                    fontFamily: 'RuneScapeRegular',
                  }}
                >
                  Affiliate Percent
                </div>
                <div></div>
                <div
                  className={`${
                    (affiliatePercent || 0) > 0 ? 'text-gold' : 'text-gold'
                  }  text-7xl font-medium flex-1`}
                  style={{
                    fontFamily: 'RuneScapeRegular',
                  }}
                >
                  {affiliatePercent
                    ? (affiliatePercent || 0) / 100 + '%'
                    : '??'}
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 ">
                <div
                  className="text-sm text-tan"
                  style={{ fontFamily: 'RuneScapeRegular' }}
                >
                  {affiliatePercent
                    ? `Affiliates earn ${(affiliatePercent || 0) /
                        100}% of every mint referred.`
                    : 'Please connect wallet to see affiliate %'}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </ErrorBoundary>
  );
};

const BalanceCard = ({
  affiliateBalance,
  tokenBalances,
  nftContract,
  _fetchTokenBalances,
}) => {
  function getNonZeroTokenBalances(tokenBalances) {
    // return true;
    // console.log({ tokenBalances });

    const nonZeroBalances = tokenBalances.filter(tokenBalance => {
      return Number(tokenBalance.balance) > 0;
    });

    // console.log({ nonZeroBalances });

    return nonZeroBalances;
  }

  const nonZeroTokenBalances = getNonZeroTokenBalances(tokenBalances);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <div
          className="shadow  "
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '11rem',
            height: '10.2rem',
          }}
        >
          <section
            className="overflow-hidden flex flex-col"
            style={{ marginInline: ' 0.5rem' }}
          >
            <div className="p-2 ">
              <div className="flex items-center mb-2">
                <div className="ml-2 w-0 flex-1">
                  <div
                    className="font-bold text-tan"
                    style={{
                      fontFamily: 'RuneScapeRegular',
                      fontSize: 'medium',
                      marginInline: '1rem',
                    }}
                  >
                    Your Loot
                  </div>
                </div>
              </div>
              <div>
                <span className="flex">
                  <div
                    className={`${
                      affiliateBalance && affiliateBalance > 0
                        ? 'text-gold '
                        : 'text-gold '
                    } text-5xl`}
                    style={{
                      fontFamily: 'RuneScapeRegular',
                      marginInline: '1rem',
                    }}
                  >
                    {affiliateBalance === undefined
                      ? '??'
                      : `${ethers.utils.formatEther(
                          affiliateBalance.toString(),
                        )}`}
                  </div>

                  <img
                    src={coins}
                    style={{ width: '45px', paddingBottom: '4.5px' }}
                    alt="coins"
                  />
                </span>
                {/* {tokenBalances.length
              ? tokenBalances.map(tokenBalance => (
                  <div
                    key={tokenBalance.currencyAddress}
                    className={`${
                      tokenBalance && Number(tokenBalance.balance) > 0
                        ? 'text-gold runescape'
                        : 'text-gold runescape'
                    } text-5xl`} style={{
                      fontFamily: 'RuneScapeRegular',
                      marginInline: '1rem'}}
                  >
                    {!tokenBalance.balance.length
                      ? '??'
                      : `${tokenBalance.balance}`}
                   <div className=" " style={{ backgroundImage: `url(${coins})`,backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}></div>
                  </div>
                ))
              : null} */}
              </div>
            </div>
            <div className="  text-sm flex flex-row ">
              <div className="ml-2 w-0 flex-1">
                <button
                  type="button"
                  onClick={async () => {
                    if (nftContract) {
                      try {
                        const estimate = await nftContract.estimateGas.withdraw();
                        console.log({ estimate });

                        await nftContract.withdraw();
                      } catch (err) {
                        console.log({ err });
                        toast(`Withdraw failed: ${err}`, TOAST_CONFIG);
                      }
                    }
                  }}
                  className={`${
                    !affiliateBalance || affiliateBalance <= 0
                      ? 'cursor-not-allowed text-neutral-500'
                      : 'text-primary hover:font-bold'
                  }
              font-bold transition-colors disabled:cursor-not-allowed text-tan`}
                  style={{
                    fontFamily: 'RuneScapeRegular',
                    fontSize: '0.7rem',
                    marginInline: '1.9rem',
                  }}
                  disabled={!affiliateBalance || affiliateBalance <= 0}
                >
                  Withdraw ETH
                </button>
              </div>
              {nonZeroTokenBalances.length ? (
                <button
                  type="button"
                  onClick={async () => {
                    if (nftContract) {
                      try {
                        const addressArray = nonZeroTokenBalances.map(
                          tokenBalance => tokenBalance.currencyAddress,
                        );

                        console.log({ addressArray });

                        const estimate = await nftContract.estimateGas.withdrawTokens(
                          addressArray,
                        );
                        console.log({ estimate });

                        const txn = await nftContract.withdrawTokens(
                          addressArray,
                        );

                        console.log({ txn });

                        const receipt = await txn?.wait();

                        console.log({ receipt });

                        _fetchTokenBalances();
                      } catch (err) {
                        console.error(err);
                        toast(`Withdraw failed: ${err}`, TOAST_CONFIG);
                      }
                    }
                  }}
                  className={`${
                    !getNonZeroTokenBalances(tokenBalances)
                      ? 'cursor-not-allowed text-neutral-400'
                      : 'text-primary hover:font-bold'
                  }
              font-bold font-display transition-colors disabled:cursor-not-allowed`}
                >
                  Withdraw Tokens
                </button>
              ) : null}
            </div>
          </section>
        </div>
      ) : (
        <div
          className="shadow  "
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '13rem',
            height: '10.2rem',
          }}
        >
          <section
            className="overflow-hidden flex flex-col"
            style={{ marginInline: ' 0.5rem' }}
          >
            <div className="p-3 ">
              <div className="flex items-center mb-4">
                <div className="ml-2 w-0 flex-1">
                  <div
                    className=" font-bold truncate tracking-wide text-tan text-4xl"
                    style={{
                      fontFamily: 'RuneScapeRegular',
                    }}
                  >
                    Your Loot
                  </div>
                </div>
              </div>
              <div>
                <span className="flex">
                  <div
                    className={`${
                      affiliateBalance && affiliateBalance > 0
                        ? 'text-gold '
                        : 'text-gold '
                    } text-5xl`}
                    style={{
                      fontFamily: 'RuneScapeRegular',
                      marginInline: '1rem',
                    }}
                  >
                    {affiliateBalance === undefined
                      ? '??'
                      : `${ethers.utils.formatEther(
                          affiliateBalance.toString(),
                        )}`}
                  </div>

                  <img
                    src={coins}
                    style={{ width: '45px', paddingBottom: '4.5px' }}
                    alt="coins"
                  />
                </span>
                {/* {tokenBalances.length
               ? tokenBalances.map(tokenBalance => (
                   <div
                     key={tokenBalance.currencyAddress}
                     className={`${
                       tokenBalance && Number(tokenBalance.balance) > 0
                         ? 'text-gold runescape'
                         : 'text-gold runescape'
                     } text-5xl`} style={{
                       fontFamily: 'RuneScapeRegular',
                       marginInline: '1rem'}}
                   >
                     {!tokenBalance.balance.length
                       ? '??'
                       : `${tokenBalance.balance}`}
                    <div className=" " style={{ backgroundImage: `url(${coins})`,backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}></div>
                   </div>
                 ))
               : null} */}
              </div>
            </div>
            <div className="  text-sm flex flex-row ">
              <div className="ml-2 w-0 flex-1">
                <button
                  type="button"
                  onClick={async () => {
                    if (nftContract) {
                      try {
                        const estimate = await nftContract.estimateGas.withdraw();
                        console.log({ estimate });

                        await nftContract.withdraw();
                      } catch (err) {
                        console.log({ err });
                        toast(`Withdraw failed: ${err}`, TOAST_CONFIG);
                      }
                    }
                  }}
                  className={`${
                    !affiliateBalance || affiliateBalance <= 0
                      ? 'cursor-not-allowed text-neutral-500'
                      : 'text-primary hover:font-bold'
                  }
               font-bold transition-colors disabled:cursor-not-allowed text-tan`}
                  style={{
                    fontFamily: 'RuneScapeRegular',
                    fontSize: '1.5rem',
                    marginInline: '1.3rem',
                  }}
                  disabled={!affiliateBalance || affiliateBalance <= 0}
                >
                  Withdraw ETH
                </button>
              </div>
              {nonZeroTokenBalances.length ? (
                <button
                  type="button"
                  onClick={async () => {
                    if (nftContract) {
                      try {
                        const addressArray = nonZeroTokenBalances.map(
                          tokenBalance => tokenBalance.currencyAddress,
                        );

                        console.log({ addressArray });

                        const estimate = await nftContract.estimateGas.withdrawTokens(
                          addressArray,
                        );
                        console.log({ estimate });

                        const txn = await nftContract.withdrawTokens(
                          addressArray,
                        );

                        console.log({ txn });

                        const receipt = await txn?.wait();

                        console.log({ receipt });

                        _fetchTokenBalances();
                      } catch (err) {
                        console.error(err);
                        toast(`Withdraw failed: ${err}`, TOAST_CONFIG);
                      }
                    }
                  }}
                  className={`${
                    !getNonZeroTokenBalances(tokenBalances)
                      ? 'cursor-not-allowed text-neutral-400'
                      : 'text-primary hover:font-bold'
                  }
               font-bold font-display transition-colors disabled:cursor-not-allowed`}
                >
                  Withdraw Tokens
                </button>
              ) : null}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

const SignUpCard = ({
  userAddress,
  setAffiliateStatus,
  affiliatePercent,
  collection,
  dbUserId,
}) => {
  const [loading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const { address: account } = useAccount();

  const handleSignUp = async () => {
    try {
      if (!account) {
        console.log('not working');
        toast('You need to log in via Metamask to sign up.', TOAST_CONFIG);
        return;
      }

      if (!COLLECTION_ADDRESS) {
        toast('No collection address is defined.', TOAST_CONFIG);
        return;
      }

      console.log('signing up');

      setIsLoading(true);

      const addAffResponse = await addSingleAffiliate({
        collectionAddress: COLLECTION_ADDRESS,
        affiliate: userAddress,
      });

      console.log({ addAffResponse });

      if (!addAffResponse || !addAffResponse.affiliateId) {
        throw new Error('Something went wrong adding a new affiliate.');
      }

      console.log({ addAffResponse });

      handleSaveClick(addAffResponse);

      setAffiliateStatus({
        isAffiliate: true,
        affiliateId: addAffResponse.affiliateId,
      });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // todo: add proper error handling
      // handleError(err, collection);
      console.error(err);
    }
  };

  async function updateUserData(formData) {
    try {
      const result = await updateUser(dbUserId, formData);
      const updatedResult = await getUser(dbUserId);
      console.log('User successfully:', result);
      console.log('User updated successfully:', updatedResult);
      // Do something with the result if needed
    } catch (error) {
      console.error('Error updating user:', error);
      // Handle errors
    }
  }

  const handleSaveClick = async addAffResponse => {
    try {
      const updatedFormData = { ...formData, ref: addAffResponse.affiliateId };
      setFormData(updatedFormData);
      console.log(updatedFormData);
      console.log('updating user affiliate....');
      console.log('affiliate: ' + addAffResponse.affiliateId);
      await updateUserData(updatedFormData); // Wait for the updateUserData function to complete
      // setIsEditing(false);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (error) {
      console.error('Error handling save click:', error);
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <div
          className="shadow  "
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '11rem',
          }}
        >
          <section
            className="overflow-hidden flex flex-col"
            style={{ height: '10.2rem' }}
          >
            <div className="p-3 overflow-x-auto">
              <div className="flex items-center mb-3">
                <div className=" w-0 flex-1">
                  <div>
                    <div
                      className=" font-bold truncate tracking-wide text-tan"
                      style={{
                        fontFamily: 'RuneScapeRegular',
                        fontSize: '1rem',
                      }}
                    >
                      Get your custom <br /> affiliate link!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" px-5 ">
              <div className="text-sm flex">
                {(affiliatePercent || 0) > 0 ? (
                  <>
                    {loading ? (
                      <div className="h-5 w-5 animate-spin mr-2">
                        <ArrowPathIcon
                          className="h-5 w-5 text-gray-600"
                          aria-hidden="true"
                        />
                      </div>
                    ) : null}
                    {collection.affiliatePrivate ? (
                      <>
                        <span
                          className=" text-tan"
                          style={{
                            fontFamily: 'RuneScapeRegular',
                          }}
                        >
                          Affiliate membership is private:
                        </span>{' '}
                        &nbsp;
                        <a
                          href={
                            collection.affiliateSignupLink ||
                            collection.website ||
                            collection.twitter
                          }
                          className={`${
                            loading
                              ? 'text-gray-500'
                              : 'text-tan hover:font-bold'
                          }`}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontFamily: 'RuneScapeRegular',
                          }}
                        >
                          Learn more
                        </a>
                      </>
                    ) : (
                      <button
                        onClick={handleSignUp}
                        className={`${
                          loading
                            ? 'text-gray-500'
                            : 'text-gold hover:font-bold'
                        } text-4xl`}
                        disabled={loading}
                        style={{
                          fontFamily: 'RuneScapeRegular',
                        }}
                      >
                        Sign Up
                      </button>
                    )}
                  </>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div
          className="shadow"
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '13rem',
          }}
        >
          <section
            className="overflow-hidden flex flex-col"
            style={{ height: '10.2rem' }}
          >
            <div className="p-3 overflow-x-auto">
              <div className="flex items-center mb-3">
                <div className=" w-0 flex-1">
                  <div>
                    <div
                      className="text-2xl font-bold text-tan"
                      style={{
                        fontFamily: 'RuneScapeRegular',
                      }}
                    >
                      Get your custom <br /> affiliate link!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" px-5 ">
              <div className="text-sm flex">
                {(affiliatePercent || 0) > 0 ? (
                  <>
                    {loading ? (
                      <div className="h-5 w-5 animate-spin mr-2">
                        <ArrowPathIcon
                          className="h-5 w-5 text-gray-600"
                          aria-hidden="true"
                        />
                      </div>
                    ) : null}
                    {collection.affiliatePrivate ? (
                      <>
                        <span
                          className=" text-tan"
                          style={{
                            fontFamily: 'RuneScapeRegular',
                          }}
                        >
                          Affiliate membership is private:
                        </span>{' '}
                        &nbsp;
                        <a
                          href={
                            collection.affiliateSignupLink ||
                            collection.website ||
                            collection.twitter
                          }
                          className={`${
                            loading
                              ? 'text-gray-500'
                              : 'text-tan hover:font-bold'
                          }`}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontFamily: 'RuneScapeRegular',
                          }}
                        >
                          Learn more
                        </a>
                      </>
                    ) : (
                      <button
                        onClick={handleSignUp}
                        className={`${
                          loading
                            ? 'text-gray-500'
                            : 'text-gold hover:font-bold'
                        } text-5xl`}
                        disabled={loading}
                        style={{
                          fontFamily: 'RuneScapeRegular',
                        }}
                      >
                        Sign Up
                      </button>
                    )}
                  </>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

const LinkCard = ({ affiliateStatus, userid, dbUserId, users }) => {
  const [copiedAddress, setCopiedAddress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  console.log('userid: ' + userid);
  const [editedUserId, setEditedUserId] = useState(userid);
  const [formData, setFormData] = useState({});

  const handleEditClick = () => {
    setIsEditing(true);
  };

  async function updateUserData(formData) {
    try {
      const result = await updateUser(dbUserId, formData);
      const updatedResult = await getUser(dbUserId);
      console.log('User successfully:', result);
      console.log('User updated successfully:', updatedResult);
      // Do something with the result if needed
    } catch (error) {
      console.error('Error updating user:', error);
      // Handle errors
    }
  }

  const handleSaveClick = async () => {
    try {
      const updatedFormData = { ...formData, username: editedUserId };
      setFormData(updatedFormData);
      console.log(updatedFormData);
      console.log('updating user....');
      console.log('username: ' + editedUserId);
      await updateUserData(updatedFormData); // Wait for the updateUserData function to complete
      setIsEditing(false);
      window.location.reload();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (error) {
      console.error('Error handling save click:', error);
    }
  };
  // const handleSaveClick = () => {
  //   setFormData({ ...formData, "username": editedUserId })
  //   console.log(formData);
  //   console.log("updating user....")
  //   console.log("username: "+editedUserId)
  //   updateUserData(formData);
  //   setIsEditing(false);
  //   // setTimeout(() => {
  //   //   window.location.reload();
  //   // }, 2000);
  // };
  // console.log("loading in link part: "+loading)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {isMobile ? (
        <div
          className="shadow  "
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '11rem',
            height: '10.2rem',
          }}
        >
          <section className="overflow-hidden flex flex-col">
            <div className="px-5 pt-3 overflow-x-auto">
              <div className="flex items-center mb-2">
                <div className="ml-1 w-0 flex-1">
                  <div>
                    <div
                      className=" font-bold text-tan truncate tracking-wide"
                      style={{
                        fontFamily: 'RuneScapeRegular',
                        fontSize: 'medium',
                      }}
                    >
                      <b>Your Affiliate Link</b>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium">
                  <p
                    className="text-tan hover:text-bold transition-colors"
                    style={{
                      fontFamily: 'RuneScapeRegular',
                    }}
                  >
                    <b>Referral Code</b> :
                  </p>
                  <p
                    className="text-tan hover:text-bold transition-colors"
                    style={{
                      fontFamily: 'RuneScapeRegular',
                      fontSize: 'smaller',
                    }}
                  >
                    {affiliateStatus.affiliateId}
                  </p>

                  <p
                    className="text-gold hover:text-bold transition-colors "
                    style={{
                      fontFamily: 'RuneScapeRegular',
                    }}
                  >
                    {isEditing ? (
                      <span className="flex">
                        <input
                          type="text"
                          className={`text-blue-600 outline-none border-none focus:ring focus:border-primary w-50% ${
                            isUsernameTaken(editedUserId, users, userid)
                              ? 'border-red-500'
                              : ''
                          }`}
                          style={{width:'70%',borderRadius:'4px'}}
                          value={editedUserId}
                          onChange={e => setEditedUserId(e.target.value)}
                        />
                        <div
                          className="h-5 w-5 text-tan group-hover:text-neutral-300 cursor-pointer transition-colors"
                          style={{marginLeft:'3px'}}
                          onClick={() => {
                            if (
                              !isUsernameTaken(editedUserId, users, userid) &&
                              !(editedUserId === userid)
                            ) {
                              handleSaveClick();
                            }
                          }}
                          disabled={isUsernameTaken(
                            editedUserId,
                            users,
                            userid,
                          )}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {' '}
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"
                                fill="#949494"
                              ></path>{' '}
                            </g>
                          </svg>
                        </div>
                      </span>
                    ) : (
                      <span className="flex">
                        <p className="text-blue-600">{userid}</p>

                        <div
                          className="h-5 w-5 text-tan group-hover:text-neutral-300 cursor-pointer transition-colors"
                          onClick={handleEditClick}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#adadad"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {' '}
                              <g id="Edit / Edit_Pencil_01">
                                {' '}
                                <path
                                  id="Vector"
                                  d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001"
                                  stroke="#949494"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>{' '}
                              </g>{' '}
                            </g>
                          </svg>
                        </div>
                      </span>
                    )}
                  </p>
                  {isUsernameTaken(editedUserId, users, userid) && (
                    <p className="text-red-400">
                      Username is already taken. Please choose another.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-quaternary px-5  ">
              <div className="text-sm">
                <div
                  className="font-mono text-sm group pb-6 pt-1"
                  onClick={() => {
                    setCopiedAddress(true);
                    navigator.clipboard.writeText(
                      `https://y2kids.net/${userid}`,
                    );

                    if (!copiedAddress) {
                      setTimeout(() => {
                        setCopiedAddress(false);
                      }, 3000);
                    }
                  }}
                >
                  <div className="flex align-center mt-0">
                    <div
                      className="font-sans font-bold pr-1 text-tan group-hover:text-neutral-100 cursor-pointer transition-colors"
                      style={{
                        fontFamily: 'RuneScapeRegular',
                      }}
                    >
                      {copiedAddress ? 'Copied!' : 'Copy Link'}
                    </div>
                    <div className="h-3 w-3 text-tan group-hover:text-neutral-300 cursor-pointer transition-colors">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div
          className="shadow  "
          style={{
            backgroundImage: `url(${compare_users})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '12.2rem',
            height: '10.2rem',
          }}
        >
          <section className="overflow-hidden flex flex-col">
            <div className="px-5 pt-3 overflow-x-auto">
              <div className="flex items-center mb-2">
                <div className="ml-1 w-0 flex-1">
                  <div>
                    <div
                      className="text-lg font-bold text-tan truncate tracking-wide"
                      style={{
                        fontFamily: 'RuneScapeRegular',
                      }}
                    >
                      <b>Your Affiliate Link</b>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium">
                  <p
                    className="text-tan hover:text-bold transition-colors"
                    style={{
                      fontFamily: 'RuneScapeRegular',
                    }}
                  >
                    <b>Referral Code</b> :
                  </p>
                  <p
                    className="text-tan hover:text-bold transition-colors"
                    style={{
                      fontFamily: 'RuneScapeRegular',
                    }}
                  >
                    {affiliateStatus.affiliateId}
                  </p>

                  <div
                    className="text-gold hover:text-bold transition-colors "
                    style={{
                      fontFamily: 'RuneScapeRegular',
                    }}
                  >
                    {isEditing ? (
                      <span className="flex">
                        <input
                          type="text"
                          className={`text-blue-600 outline-none border-none focus:ring focus:border-primary ${
                            isUsernameTaken(editedUserId, users, userid)
                              ? 'border-red-500'
                              : ''
                          }`}
                          style={{width:'70%',borderRadius:'4px'}}
                          value={editedUserId}
                          onChange={e => setEditedUserId(e.target.value)}
                        />
                        <div
                          className="h-5 w-5 text-tan group-hover:text-neutral-300 cursor-pointer transition-colors"
                          style={{marginLeft:'3px'}}
                          onClick={() => {
                            if (
                              !isUsernameTaken(editedUserId, users, userid) &&
                              !(editedUserId === userid)
                            ) {
                              handleSaveClick();
                            }
                          }}
                          disabled={isUsernameTaken(
                            editedUserId,
                            users,
                            userid,
                          )}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {' '}
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"
                                fill="#949494"
                              ></path>{' '}
                            </g>
                          </svg>
                        </div>
                      </span>
                    ) : (
                      <div className="flex">
                        <div className="text-blue-600">{userid}</div>

                        <div
                          className="h-5 w-5 text-tan group-hover:text-neutral-300 cursor-pointer transition-colors"
                          onClick={handleEditClick}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#adadad"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {' '}
                              <g id="Edit / Edit_Pencil_01">
                                {' '}
                                <path
                                  id="Vector"
                                  d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001"
                                  stroke="#949494"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>{' '}
                              </g>{' '}
                            </g>
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                  {isUsernameTaken(editedUserId, users, userid) && (
                    <p className="text-red-400">
                      Username is already taken. Please choose another.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-quaternary px-5  ">
              <div className="text-sm">
                <div
                  className="font-mono text-sm group pb-6 pt-1"
                  onClick={() => {
                    setCopiedAddress(true);
                    navigator.clipboard.writeText(
                      `https://y2kids.net/${userid}`,
                    );

                    if (!copiedAddress) {
                      setTimeout(() => {
                        setCopiedAddress(false);
                      }, 3000);
                    }
                  }}
                >
                  <div className="flex align-center mt-0">
                    <div
                      className="font-sans font-bold pr-1 text-tan group-hover:text-neutral-100 cursor-pointer transition-colors"
                      style={{
                        fontFamily: 'RuneScapeRegular',
                      }}
                    >
                      {copiedAddress ? 'Copied!' : 'Copy Link'}
                    </div>
                    <div className="h-3 w-3 text-tan group-hover:text-neutral-300 cursor-pointer transition-colors">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
