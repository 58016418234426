import { fetchMerkleProof } from 'components/clientApi';
import { clsx } from 'clsx';
import { ethers, providers } from 'ethers';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { usePublicClient, useWalletClient } from 'wagmi';

// todo: import signer and provider
export async function checkERC20Allowances({
  invite,
  account,
  setCurrentStep,
  setModalData,
  library,
  collection,
  totalBN,
  signer,
}) {
  if (invite.currencyAddress === ethers.constants.AddressZero) {
    return;
  }

  console.log({ totalBN: totalBN.toString() });

  const erc20Abi = require('components/abi/Erc20.json');

  const erc20Contract = new ethers.Contract(
    invite.currencyAddress || '',
    erc20Abi,
    library,
  ).connect(signer);

  const allowance = await erc20Contract.allowance(
    account,
    collection.token_address,
  );

  if (totalBN.gt(allowance) || allowance.toString() === '0') {
    console.log({ invite });

    console.log({
      allowance,
      price: totalBN,
      symbol: invite.symbol,
      collectionSymbol: collection.symbol,
      decimals: invite.decimals || 18,
    });

    setModalData(prev => ({
      ...prev,
      allowance,
      price: totalBN,
      symbol: invite.symbol,
      collectionSymbol: collection.symbol,
      decimals: invite.decimals || 18,
    }));

    setCurrentStep(2);

    const approvalResTxn = await erc20Contract.approve(
      collection.token_address,
      ethers.constants.MaxUint256,
    );

    console.log({ approvalResTxn });

    if (!approvalResTxn || !approvalResTxn.hash) {
      return;
    }

    setModalData(prev => ({
      ...prev,
      txnHash: approvalResTxn.hash,
    }));

    const receipt = await approvalResTxn?.wait();

    console.log({ receipt });
    setCurrentStep(1);

    setModalData(prev => ({
      ...prev,
      txnHash: '',
    }));
  }
}

export function getValueBN(
  invite,
  mintQuantity,
  affiliateId,
  computeUnitPrice,
) {
  const unitSize = Math.max(1, invite.unitSize || 1);

  const priceBN = computeUnitPrice(
    mintQuantity * unitSize,
    affiliateId,
    invite,
  );

  // console.log({ unitSize, priceBN: priceBN.toString() });

  if (!priceBN) {
    throw new Error('No price available.');
  }
  const quantityBN = ethers.BigNumber.from(mintQuantity);

  // console.log({ quantityBN: quantityBN.toString() });

  const valueBN =
    invite.currencyAddress === ethers.constants.AddressZero
      ? priceBN.mul(quantityBN).mul(unitSize)
      : ethers.BigNumber.from(0);

  const totalBN = priceBN.mul(quantityBN).mul(unitSize);

  return { valueBN, priceBN, totalBN, quantityBN, unitSize };
}

export async function getValueBondingCurve({
  invite,
  mintQuantity,
  nftContract,
}) {
  if (!nftContract) {
    throw new Error('No price available.');
  }

  if (mintQuantity <= 0) {
    throw new Error("Mint quantity can't be zero.");
  }

  const unitSize = Math.max(1, invite.unitSize || 1);

  const priceBN = await nftContract.computePrice(
    invite.root,
    mintQuantity,
    false,
  );

  if (!priceBN) {
    throw new Error('No price available.');
  }

  const quantityBN = ethers.BigNumber.from(mintQuantity);

  const valueBN =
    invite.currencyAddress === ethers.constants.AddressZero
      ? priceBN.mul(unitSize)
      : ethers.BigNumber.from(0);

  const totalBN = priceBN.mul(unitSize);

  return { valueBN, priceBN, totalBN, quantityBN, unitSize };
}

export const computeUnitPrice = discount => (
  mintQuantity,
  affiliateId,
  list,
) => {
  // console.log({ list });
  let cost = ethers.utils.parseUnits(list.token_price, list.decimals || 18);

  if (affiliateId && affiliateId.length > 0) {
    cost = cost.sub(cost.mul(discount.affiliateDiscount).div(10000));
  }
  // note: set on etherscan like this: ["0",[["10","4000"],["5","2000"]]]

  if (!discount.mintTiers?.length) {
    return cost;
  }

  for (const tier of discount.mintTiers) {
    if (mintQuantity >= tier.numMints) {
      cost = cost.sub(cost.mul(tier.mintDiscount).div(10000));
      console.log({ cost: ethers.utils.formatUnits(cost, list.decimals) });
      return cost;
    }
  }

  return cost;
};

export function publicClientToProvider(publicClient) {
  const { chain, transport } = publicClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  if (transport.type === 'fallback')
    return new providers.FallbackProvider(
      transport.transports.map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network),
      ),
    );
  return new providers.JsonRpcProvider(transport.url, network);
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId } = {}) {
  const publicClient = usePublicClient({ chainId });
  return useMemo(() => publicClientToProvider(publicClient), [publicClient]);
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const getNftContract = ({ collectionAddress, signer, provider }) => {
  const archetypeAbi = require('components/abi/ArchetypeV60.json');

  const contract = new ethers.Contract(
    collectionAddress,
    archetypeAbi,
    provider,
  ).connect(signer);

  return contract;
};

export function walletClientToSigner(walletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });
  return useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : undefined),
    [walletClient],
  );
}

export const isStarted = startTime => {
  if (!startTime) {
    return false;
  }

  const time = typeof startTime === 'string' ? new Date(startTime) : startTime;

  const timeLeftDate = time.getTime() - new Date().getTime();

  return timeLeftDate < 0;
};

export function isPublicList(root) {
  return BigInt(root) <= BigInt(0xff);
}

export const getProof = async ({ collection, invite, userAddress }) => {
  console.log({ root: invite.root });

  if (!collection.token_address) {
    throw new Error('Collection address is not defined.');
  }

  if (invite.numAddresses === 0 || isPublicList(invite.root)) {
    return [];
  }

  console.log({ cached: collection.proof_cached });

  const proofRes = await fetchMerkleProof(
    collection.token_address,
    invite.root,
    userAddress,
    collection.proof_cached,
  );

  // console.log({ proofRes });

  if (!proofRes) {
    throw new Error('Failed to fetch merkle proof.');
  }
  return proofRes.proof;
};

export function shortenAddress(address) {
  if (!address || !address.length) {
    return '';
  }
  return (
    address.substring(0, 6) + '...' + address.substring(address.length - 4)
  );
}

export function shortenEns(address) {
  if (!address || !address.length) {
    return '';
  }
  if (address.length < 22) {
    return address;
  }
  return (
    address.substring(0, 18) + '..' + address.substring(address.length - 4)
  );
}

export function validateAddressIs0xOrEns(input: string) {
  if (input.endsWith('.eth')) {
    // Input is an ENS name
    const normalized = ethers.utils.formatBytes32String(
      input.substring(0, input.length - 4),
    );
    console.log({ normalized });
    return ethers.utils.isHexString(normalized, 32);
  } else {
    // Input is an Ethereum address
    return ethers.utils.isAddress(input);
  }
}

const adjectives = ['Sneaky', 'Swift', 'Crimson', 'Daring', 'Fierce', 'Mystic', 'Eternal', 'Golden', 'Radiant', 'Infinite'];
const nouns = ['Shadow', 'Wolf', 'Blade', 'Phoenix', 'Sword', 'Dragon', 'Sorcerer', 'Titan', 'Champion', 'Oracle'];

export function generateUsername(existingUsernames) {
  // console.log(existingUsernames);
  const isUsernameTaken = (username) => existingUsernames.some(user => user.username === username);

  let username;
  do {
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const noun = nouns[Math.floor(Math.random() * nouns.length)];
    const number = Math.floor(Math.random() * 90000) + 10000;
    username = `${adjective}${noun}${number}`;
  } while (isUsernameTaken(username));

  return username;
}

export function isUsernameTaken(username, existingUsernames, oldUserid) {
  if (username === oldUserid) {
    return false;
  }

  return existingUsernames.some(user => user.username === username);
}

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export function truncate(longString, limit = 12){
    if (longString.length > limit) {
        return (longString.substring(0, 5) + '...' + longString.substring(5, limit))
    }

    return longString
}