import { topAffiliatesApi } from 'components/clientApi';
import { ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
// import { useAccount } from 'wagmi';
import { useTable } from 'react-table';
// import { generateUsername } from '../../../components/utils'
// import { getUsers, addUser } from '../../../components/usernameHelper'
import scroll_middle from '../../../assets/scroll_middle.png';
import scroll_top from '../../../assets/scroll_top.png';
import scroll_bottom from '../../../assets/scroll_bottom.png';
import { shortenEns, shortenAddress } from '../../../components/utils';
import { ETHERSCAN_DOMAIN } from 'components/constants';
// import { useAccount, useEnsName } from 'wagmi';

const dateRangeArray = ['24h', '7d', '30d', 'All'];

export function DateRangeButtons({ setActiveDateRange, activeDateRange }) {
  return (
    <span className="relative z-0 inline-flex  shadow-sm">
      {dateRangeArray.map((val, i) => {
        const isLast = i === dateRangeArray.length - 1;
        const isFirst = i === 0;

        return (
          <button
            type="button"
            key={val}
            onClick={() => {
              setActiveDateRange(val);
            }}
            className={`border align-center justify-center relative inline-flex items-center px-4 py-2 text-sm font-medium focus:z-10 focus:border-primary focus:outline-none transition-all z-0
           ${isLast ? '' : isFirst ? '' : ''} ${
              activeDateRange === val
                ? 'text-black border-black border-r'
                : 'text-gray-700 hover:text-gray-700 border-gray-700 hover:z-10 hover:border-gray-700 hover:scale-105'
            }
            ${
              activeDateRange === val || isLast
                ? 'border-r'
                : 'border-r-0 hover:border-r'
            } `}
          >
            {val}
          </button>
        );
      })}
    </span>
  );
}

export function LeaderboardTable({
  affiliates,
  activeDateRange,
  setActiveDateRange,
  users,
  loading,
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // const [users, setUsers] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const { address, isConnected } = useAccount()
  // // console.log({ affiliatesTable: affiliates });
  // const pkey = isConnected ? address : '';

  // const fetchUsers = async () => {
  //   try {
  //       const users = await getUsers();
  //       setUsers(users);
  //       console.log(users);
  //       // Do something with the users data
  //   } catch (error) {
  //       console.error('Error fetching users:', error);
  //   } finally {
  //     setLoading(false); // Set loading to false once users are fetched or an error occurs
  //   }
  // };

  // useEffect(() => {
  //   fetchUsers();
  // }, [isConnected]);

  // const publicKeyExists = users?.some(obj => Object.keys(obj)?.some(key => obj[key] === pkey));

  // // const addMutation = useMutation(addUser, {
  // //   onSuccess : () => {
  // //              queryClient.prefetchQuery('users', getUsers)
  // //          }
  // //      })

  //  const addUserToApi = async () => {
  //    try {
  //            const userData = {
  //              "username":generateUsername(),
  //              "pubKey":address,
  //          };
  //          const result = await addUser(userData);
  //          console.log(result);
  //          // Handle the result (response from the server) as needed
  //      } catch (error) {
  //          console.error('Error adding user:', error);
  //      }
  //  };

  //  const handleSubmit = (e) => {
  //    console.log("adding user....")
  //      addUserToApi();
  //    }

  //    useEffect(() => {
  //     console.log("isConnected:"+isConnected+"loading:"+loading);
  //     if (isConnected && !loading ) {
  //       if(publicKeyExists===false){
  //       handleSubmit();
  //       }
  //     }
  //   }, [isConnected, loading, address]);

  // console.log(address, connector, isConnected)
  const sortedAffiliates = affiliates.sort((a, b) => {
    const commissionA = parseFloat(a.totalCommissionEth);
    const commissionB = parseFloat(b.totalCommissionEth);

    return commissionB - commissionA;
  });
  // console.log(sortedAffiliates)

  const affiliateRows = sortedAffiliates.map((affiliate, i) => {
    const matchingUser = users.find(user => user.pubKey === affiliate.address);

    if (matchingUser && !loading) {
      return {
        rank: <span className="text-black runscapeFont">{i}</span>,
        name: (
          <span className="text-black runscapeFont">
            {matchingUser.username}
          </span>
        ), // Assuming 'username' is a property in the 'users' object
        address: (
          <a
            className="text-primary hover:font-bold font-mono"
            href={`${ETHERSCAN_DOMAIN}/address/${affiliate.address}`}
          >
            {affiliate.ens
              ? shortenEns(affiliate.ens)
              : shortenAddress(affiliate.address)}
          </a>
        ),
        totalCommissionEth: (
          <span className="font-mono text-right">
            {affiliate.totalCommissionEth}
            <span className="text-black ml-1">
              {ethers.constants.EtherSymbol}
            </span>
          </span>
        ),
      };
    }

    return <div>Loading...</div>; // If no matching user is found, you can handle this case as needed
  });

  const data = useMemo(() => affiliateRows, [affiliateRows]);

  const columns = useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'rank',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Loot',
        accessor: 'totalCommissionEth',
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <>
      {isMobile ? (
        <div className="flex flex-col ">
          <div
            style={{
              backgroundImage: `url(${scroll_middle})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'repeat-y',
              width: '99%',
              marginInline: 'auto',
              overflow: 'auto',
            }}
            className=""
          >
            <div
              style={{
                backgroundImage: `url(${scroll_top})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '50px',
                width: '100%',
              }}
              className=" "
            ></div>

            {/* <div className="px-6 pt-6 pb-5 bg-tertiary   flex justify-between ">
        <div className="text-lg font-semibold text-black">Top 20 Affiliates</div>

        <div>
          <DateRangeButtons
            activeDateRange={activeDateRange}
            setActiveDateRange={setActiveDateRange}
          />
        </div>
      </div> */}
            {/* <div className="h-3 bg-tertiary ">&nbsp;</div> */}
            <table
              {...getTableProps()}
              className=" pb-10 text-sm text-neutral-250 "
              style={{ marginInline: 'auto' }}
            >
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={i}
                    className="text-sm"
                  >
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        key={column.id}
                        className={`${
                          column.id === 'address'
                            ? 'pl-2 sm:pl-6 text-left'
                            : 'pl-2 sm:pl-6 text-left'
                        } py-2 bg-quaternary font-normal tracking-wide text-black runscapeFont`}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={i}
                      className={`px-4  ${
                        i % 2 === 0 ? 'bg-tertiary' : 'bg-secondary'
                      }`}
                    >
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={cell.column.id}
                            className={`pr-2 py-2 sm:px-6 text-black ${
                              cell.column.id === 'address' ? '' : ''
                            }`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <div className="h-3 bg-tertiary ">&nbsp;</div> */}
            <div
              style={{
                backgroundImage: `url(${scroll_bottom})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '50px',
                width: '100%',
              }}
              className="flex justify-center"
            ></div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col ">
          <div
            style={{
              backgroundImage: `url(${scroll_middle})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'repeat-y',
              width: '61%',
              marginInline: 'auto',
              overflow: 'auto',
            }}
            className=""
          >
            <div
              style={{
                backgroundImage: `url(${scroll_top})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '100px',
                width: '100%',
              }}
              className=" "
            ></div>

            {/* <div className="px-6 pt-6 pb-5 bg-tertiary   flex justify-between ">
        <div className="text-lg font-semibold text-black">Top 20 Affiliates</div>

        <div>
          <DateRangeButtons
            activeDateRange={activeDateRange}
            setActiveDateRange={setActiveDateRange}
          />
        </div>
      </div> */}
            <table
              {...getTableProps()}
              className=" pb-10 text-sm text-neutral-250 "
              style={{ marginInline: 'auto' }}
            >
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={i}
                    className="text-sm"
                  >
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        key={column.id}
                        className={`${
                          column.id === 'address'
                            ? 'pl-2 sm:pl-6 text-left'
                            : 'pl-2 sm:pl-6 text-left'
                        } py-2 bg-quaternary font-normal tracking-wide text-black runscapeFont`}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={i}
                      className={`px-4  ${
                        i % 2 === 0 ? 'bg-tertiary' : 'bg-secondary'
                      }`}
                    >
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={cell.column.id}
                            className={`pr-2 py-2 sm:px-6 text-black ${
                              cell.column.id === 'address' ? '' : ''
                            }`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <div className="h-3 bg-tertiary ">&nbsp;</div> */}
            <div
              style={{
                backgroundImage: `url(${scroll_bottom})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '100px',
                width: '100%',
              }}
              className="flex justify-center"
            ></div>
          </div>
        </div>
      )}
    </>
  );
}

export default function LeaderboardSection({ pid, users, loading }) {
  const [affiliates, setAffiliates] = useState([]);

  const [activeDateRange, setActiveDateRange] = useState('All');

  useEffect(() => {
    async function getAffiliateStatus() {
      // console.log("fetching top affiliates");
      if (!pid) {
        return;
      }

      const affiliateDataRes = await topAffiliatesApi([
        'collectionAddress',
        ethers.utils.getAddress(pid),
        'range',
        activeDateRange,
      ]);

      // console.log({ affiliateDataRes });

      if (affiliateDataRes) {
        setAffiliates(affiliateDataRes.affiliates);
      }
    }

    try {
      getAffiliateStatus();
    } catch (err) {
      console.error(err);
    }
  }, [activeDateRange, pid]);

  return (
    <LeaderboardTable
      affiliates={affiliates}
      activeDateRange={activeDateRange}
      setActiveDateRange={setActiveDateRange}
      users={users}
      loading={loading}
    />
  );
}
