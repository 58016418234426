// import React, { useState } from 'react';
import styled from 'styled-components';
import {useState, useEffect} from 'react';
export default function FaqsBox({ onClose }) {
  // const [copiedAddress, setCopiedAddress] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {isMobile ? (
      // Content to render for mobile devices
      <Div>
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>
      <div className='break-all'>
      Supply + Price?
      <br/>
-5555 total Y2Kids, public mint is 0.025 ETH.
</div>

<br/><br/>

<div className=' break-all'>
Free mints?!
<br/>
-Yes! 20% will be allocated to a handful of Remilia +<br/> Egregore collection holders, first come first serve!<br/> If you're late to the mints, you WILL miss out!
</div>

<br/><br/>

<div className=' break-all'>
What's the Y2Kids Vision?
<br/>
-The 90's-2000's era was incredibly special for all of us,<br/> even though we didn't realize it until it had past, and<br/>we want our Y2Kids to transport us back to that warm,<br/>happy & nostalgic time in our lives. ❤️
</div>

<br/><br/>

<div className=' break-all'>
Do you have an Affiliate Commission Program?
<br/>
-Yes, we do! Take 20 seconds to sign up in our affiliate <br/>program app, and tweet out your custom link! <br/>Affiliates earn 15% of the ETH from the mints through <br/>their links!
</div>

<br/><br/>

<div className='break-all'>
Will an eco sweep happen?
<br/>
-Yes, after costs, 15% will be used for some<br/> floor sweeping!
</div>

<br/><br/>

<div className=' break-all'>
Info on the custom matching 3D models?
<br/>
-Every single Y2Kid will have a matching 3D model made<br/> that can be used as a VTuber. We expect these to take 2-3 months before delivery is possible, as the creation process is extremely long due to the sheer amount of traits that<br/> will have to be virtualized! Remember, these are a cream of the crop bonus!
</div>

<br/><br/>

<div className=' break-all'>
How to get my mascot model for minting 10+?
<br/>
-Every single person who mints 10+, will be able to use an app 7-10 days post mint on our website, to verify and <br/>claim their mascot model file. <br/>You do NOT need to hold all 10+ until then!
</div>

<br/><br/>

<div className=' break-all'>
How do I use my custom 3D models?
<br/>
-There are simple YouTube guides on how to do this, <br/>it's very easy actually! <br/>We will put up a step-by-step guide on our website near <br/>the delivery of our custom models though!
      </div>
      
      </section>
    </Div>
    ) : (
      <Div>
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>
      <div className='break-all'>
      Supply + Price?
      <br/>
-5555 total Y2Kids, public mint is 0.025 ETH.
</div>

<br/><br/>

<div className=' break-all'>
Free mints?!
<br/>
-Yes! 20% will be allocated to a handful of Remilia + Egregore collection holders, first come first serve! If you're late to the mints, you WILL miss out!
</div>

<br/><br/>

<div className=' break-all'>
What's the Y2Kids Vision?
<br/>
-The 90's-2000's era was incredibly special for all of us, even though we didn't realize it until it had past, and we want our Y2Kids to transport us back to that warm, happy, nostalgic time in our lives. ❤️
</div>

<br/><br/>

<div className=' break-all'>
Do you have an Affiliate Commission Program?
<br/>
-Yes, we do! Take 20 seconds to sign up in our affiliate program app, and tweet out your custom link! Affiliates earn 15% of the ETH from the mints through their links!
</div>

<br/><br/>

<div className='break-all'>
Will an eco sweep happen?
<br/>
-Yes, after costs, 15% will be used for some floor sweeping!
</div>

<br/><br/>

<div className=' break-all'>
Info on the custom matching 3D models?
<br/>
-Every single Y2Kid will have a matching 3D model made that can be used as a VTuber. We expect these to take 2-3 months before delivery is possible, as the creation process is extremely long due to the sheer amount of traits that will have to be virtualized! Remember, these are a cream of the crop bonus!
</div>

<br/><br/>

<div className=' break-all'>
How to get my mascot model for minting 10+?
<br/>
-Every single person who mints 10+, will be able to use an app 7-10 days post mint on our website, to verify and claim their mascot model file. You do NOT need to hold all 10+ until then!
</div>

<br/><br/>

<div className=' break-all'>
How do I use my custom 3D models?
<br/>
-There are simple YouTube guides on how to do this, it's very easy actually! We will put up a step-by-step guide on our website near the delivery of our custom models though!
      </div>
      
      </section>
    </Div>
      
    )}
   
    </>
  );
}

const Div = styled.div`
  height: 100%;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  overflow:auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 100;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }
`;
