"use client";

import React, { useEffect, useState } from "react";
// import { Routes, Route, Outlet, Link, useHistory  } from 'react-router-dom';
import { Login } from "Login";
import WinXP from "WinXP";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
// import { useGA } from 'hooks';
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import clippy from "clippyts";
import { ErrorBoundary } from "react-error-boundary";
import { connect, useSelector } from "react-redux";
import { WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { useLogin } from "./LoginContext";
import { getUsers } from "./components/usernameHelper";

const projectId = "6e4e65d8e34ea3d9983d0d19732efb27";

const metadata = {
  name: "Y2Kids",
  description: "nft project",
  url: "https://scatter.art",
  icons: [
    "https://pbs.twimg.com/profile_images/1658825660876333056/nU9eZXWx_400x400.jpg",
  ],
};

const chains = [mainnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({
  themeMode: "dark",
  defaultChain: mainnet,
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    "--w3m-color-mix": "#0831D9",
    "--w3m-color-mix-strength": 40,
  },
});

const App = ({ count }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // useGA('UA-135148027-3', 'winXP');
  const { loginCheck } = useLogin();
  const counter = useSelector(state => state.counter.counter);

  clippy.load({
    name: "Clippy",
    selector: "my-clippy",
    successCb: agent => {
      if (loginCheck) {
        // agent.show();
        // agent.speak("Welcome to Y2Kids!");
        // setTimeout(() => {
        //   // console.log('Delayed message after 2000 milliseconds');
        //   agent.hide();
        // }, 4000);
      }
      if (counter === 2) {
        agent.show();
        if (isMobile) {
          // Do something specific for mobile devices
          agent.reposition("clippy-top-right");
        }
        agent.speak(
          "Hi User! Glad to see your dial-up worked! I recommend minting at least 5+ to boost your chances of pulling rares! Mint 10+ for a bonus gift! ;)",
        );
        setTimeout(() => {
          agent.hide();
        }, 8000);
      } else if (counter === 5) {
        agent.show();
        if (isMobile) {
          // Do something specific for mobile devices
          agent.reposition("clippy-top-right");
        }
        agent.speak(
          "Hello again User! If you mint 10+, you'll unlock our usable Y2Kids Mascot VTuber file as a bonus! Whoa! Don't miss this!",
        );
        setTimeout(() => {
          agent.hide();
        }, 8000);
      }
    },
    failCb: e => {
      console.error(e);
    },
  });
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const fetchUsers = async () => {
    try {
      const users = await getUsers();
      setUsers(users);
      // console.log(users);
      // Do something with the users data
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false); // Set loading to false once users are fetched or an error occurs
    }
  };
  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={details => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <WagmiConfig config={wagmiConfig}>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="home" element={<WinXP />} />
            <Route path="*" element={<NoMatch />} />
            {/* <Route path="/:username" element={<RedirectToRef users={users} loading={loading} />} /> */}
            {!loading && (
              <Route
                path="/:username"
                element={<RedirectToRef users={users} />}
              />
            )}
          </Route>
        </Routes>
      </WagmiConfig>
    </ErrorBoundary>
  );
};

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const RedirectToRef = users => {
  const { username } = useParams();

  // Assuming you have a function to generate the referral link based on the username
  const referralLink = findReferralCode(username, users);

  // Redirect to the referral link
  return referralLink ? <Navigate to={referralLink} /> : null;
};

// const generateReferralLink = (username) => {
//   // Generate your referral link based on the provided username
//   // For example:
//   return `/ref=656c928c7c1d6dc04621c133?username=${username}`;
// };

function findReferralCode(username, dataArray) {
  // console.log(dataArray)
  const { users } = dataArray;
  // console.log(users)
  const userObject = users?.find(obj => obj.username === username);
  // const desiredObject = users?.find(obj => obj.pubKey === pkey);
  if (userObject && userObject.ref) {
    return `/?ref=${userObject.ref}`;
  }

  return null; // Return null if the username or referral code is not found
}
// const RedirectToRef = () => {
//   const history = useHistory();

//   React.useEffect(() => {
//     // Get the username from the URL parameter
//     const { username } = useParams();

//     // Assuming you have a function to generate the referral link based on the username
//     const referralLink = generateReferralLink(username);

//     // Redirect to the referral link
//     history.replace(referralLink);
//   }, [history]);

//   return null;
// };

const mapStateToProps = state => {
  return {
    count: state.count,
  };
};
// export default App;
export default connect(mapStateToProps)(App);

function NoMatch() {
  return (
    <div>
      <h2>Redirecting...</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
