import { TimeLeftInviteList } from "components/timer/TimeLeftInviteList";
import { ETHERSCAN_DOMAIN } from "components/constants";
import { isStarted, validateAddressIs0xOrEns } from "components/utils";
import { ExclamationCircleIcon, GiftIcon } from "@heroicons/react/20/solid";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
// import clippy from 'clippyts'
// import { connect } from 'react-redux';
// import { setMintQuantity } from '../../../redux/actions';
// import { increaseCount,decreaseCount } from 'counterActions';
import { useDispatch } from "react-redux";
import { increment, decrement } from "redux/features/counterSlice";
import hand1 from "../../../assets/hand1.png";
import star1 from "../../../assets/star1.png";
// import heart1 from '../../../assets/heart1.png'
import debounce from "lodash.debounce";

export function MintPriceCalculation({ mintQuantity, price, list }) {
  return (
    <>
      <span className="text-blue-700">
        {`${mintQuantity} * ${formatPriceBN(1, price, list)} each =`}{" "}
        {/* {`${mintQuantity} * ${0.025} each =`}{" "} */}
      </span>
      <span className="text-primary text-blue-700">
        {formatPriceBN(mintQuantity, price, list)}{" "}
        {/* {(mintQuantity * 0.025).toFixed(3)}{" "} */}
        {list.currencyAddress !== ethers.constants.AddressZero
          ? list.symbol || ""
          : ethers.constants.EtherSymbol}
      </span>
    </>
  );
}

function formatPriceBN(mintQuantity, price, list) {
  const priceFormatted = Number(
    ethers.utils.formatUnits(
      ethers.BigNumber.from(mintQuantity).mul(price),
      list.decimals,
    ),
  );

  return list.currencyAddress === ethers.constants.AddressZero
    ? priceFormatted
    : priceFormatted > 1
    ? priceFormatted.toLocaleString()
    : priceFormatted;
  // : priceFormatted.toLocaleString(undefined, {
  //     minimumFractionDigits: 8,
  //     maximumFractionDigits: 8,
  //   });
}

export const MintSection = ({
  list,
  max_items: _maxItems,
  affiliateId,
  handleMintClick,
  computeUnitPrice,
  affiliateData,
  affiliateFee,
  erc20BalanceSection,
  mintPriceCalculator,
  // increaseCount,
  // decreaseCount
}) => {
  const dispatch = useDispatch();

  const [mintQuantity, setMintQuantity] = useState(1);
  // const { mintQuantityPassed } = useMint();
  const [startTimePassed, setStartTimePassed] = useState(
    isStarted(list.start_time),
  );

  const max_items = Array.isArray(_maxItems) ? _maxItems.length : _maxItems;
  // const unitSize = list.unitSize ? (list.unitSize > 1 ? list.unitSize : 1) : 1;

  const [giftAddress, setGiftAddress] = useState({
    gifting: false,
    address: "",
    isValid: false,
  });
  const handleInputChange = debounce(value => {
    setGiftAddress(prev => ({
      ...prev,
      address: value,
      isValid: validateAddressIs0xOrEns(value),
    }));
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedHandleMintClick = useCallback(
    handleMintClick({
      invite: list,
      mintQuantity: mintQuantity,
      computeUnitPrice,
      giftAddress,
    }),
    [list, mintQuantity, handleMintClick, computeUnitPrice, giftAddress],
  );

  useEffect(() => {
    if (!list.start_time) {
      return;
    }

    const startTime = new Date(list.start_time);

    const timeUntilStart = startTime.getTime() - Date.now();

    // console.log({ timeUntilStart });

    if (timeUntilStart < 0) {
      setStartTimePassed(true);
    } else {
      const timerId = setTimeout(() => {
        setStartTimePassed(true);
      }, timeUntilStart);

      return () => clearTimeout(timerId);
    }
  }, [list.start_time]);

  // todo: create computedPrice variable once so we don't have to call computeUnitPrice over and over
  return (
    <div className="mb-6 w-full border-b border-neutral-700 pb-6">
      <div className="ml-2 tracking-wider">
        {/* <span className="text-primary font-bold text-xs">
        {list.name === 'Public' ? (
           <span className='flex'>
            <span>{list.name || 'Untitled'}</span>
            <img src={heart1} width={'20px'} alt="heart icon" className='ml-2' />
          </span>
          ) : (
            <span className='flex'>
            <span>{list.name || 'Untitled'}</span>
            <img src={star1} width={'20px'} alt="star icon" className='ml-2' />
            </span>
          )}
        </span> */}
        <span className="text-primary font-bold text-xs">
          {
            <span className="flex">
              <span>{list.name}</span>
              <img
                src={star1}
                width={"20px"}
                alt="star icon"
                className="ml-2"
              />
            </span>
          }
        </span>
        {list.limit && list.limit < max_items ? (
          <>
            <span className="text-neutral-600 font-light"> | </span>
            <span
              className={`text-xs ${
                list.numMinted && list.numMinted >= list.limit
                  ? "text-red-700"
                  : "text-gray-500"
              }`}
            >
              {" "}
              wallet limit: {list.numMinted || "?"}/{list.limit}
            </span>
          </>
        ) : null}
        {list.listLimit && list.listLimit < max_items ? (
          <>
            <span className="text-neutral-600 font-light"> | </span>
            <span
              className={`text-xs ${
                list?.numMintedOnList && list.numMintedOnList >= list.listLimit
                  ? "text-red-700"
                  : "text-gray-500"
              }`}
            >
              {" "}
              list limit: {list.numMintedOnList || "?"}/{list.listLimit}
            </span>
          </>
        ) : null}
      </div>

      <div className="flex flex-row justify-between items-end w-full mt-2 text-xs">
        <div className="block font-medium text-gray-400 ml-2 flex-none">
          {mintPriceCalculator(mintQuantity, setMintQuantity)}
        </div>
        <TimeLeftInviteList list={list} startTimePassed={startTimePassed} />
      </div>

      <div className="flex flex-row justify-start items-center mt-2 w-full mx-auto text-xs">
        <div className="grow mr-4">
          <div>
            <div className="flex">
              <input
                type="number"
                name="quantity"
                id="quantity"
                className="font-secondary bg-quaternary-l shadow-sm border focus:ring-primary focus:border-primary block w-full border-neutral-800 h-12 p-4 sm:text-xs text-gray-600"
                placeholder="0"
                aria-describedby="quantity to mint"
                min="1"
                max={list.limit || 1000}
                value={mintQuantity}
                onChange={evt => {
                  const newQuantity = +evt.target.value;
                  // Check if newQuantity is greater or less than mintQuantity
                  if (newQuantity > mintQuantity) {
                    dispatch(increment());
                  } else if (newQuantity < mintQuantity) {
                    dispatch(decrement());
                  }
                  // Update mintQuantity
                  setMintQuantity(newQuantity);
                }}
              />
              <span className="flex flex-col ml-1">
                <button
                  className=" px-2 border rounded-sm focus:outline-none bg-white border-black mb-1"
                  style={{ paddingBlock: "2.4px" }}
                  onClick={() => {
                    if (mintQuantity < (list.limit || 1000)) {
                      dispatch(increment());
                      setMintQuantity(mintQuantity + 1);
                    }
                  }}
                >
                  ▲
                </button>
                <button
                  className=" px-2 rounded-sm focus:outline-none bg-white border border-black"
                  style={{ paddingBlock: "2.4px" }}
                  onClick={() => {
                    if (mintQuantity > 1) {
                      dispatch(decrement());
                      setMintQuantity(mintQuantity - 1);
                    }
                  }}
                >
                  ▼
                </button>
              </span>
            </div>
          </div>
        </div>

        <div className="flex-none">
          <button
            type="button"
            onClick={memoizedHandleMintClick}
            className={`${
              !startTimePassed
                ? "bg-neutral-800 cursor-not-allowed text-neutral-500"
                : "bg-primary hover:bg-white text-gray-600 hover:text-blue-500 hover:border-blue-500 "
            } w-36 h-12 text-xs inline-flex bg-white border-blue-700 rounded-md items-center justify-center px-6 py-3 border font-semibold font-secondary tracking-wide shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-all duration-200`}
            disabled={!startTimePassed}
          >
            Mint
          </button>
        </div>
      </div>
      {affiliateFee > 0 ? (
        <AffiliateCodeMessage
          {...{
            affiliateId,
            affiliateData,
          }}
        />
      ) : null}
      {list.currencyAddress !== ethers.constants.AddressZero ? (
        <div className="mt-3">{erc20BalanceSection}</div>
      ) : null}
      <fieldset className="flex flex-row justify-end pr-1 mt-3">
        <div className="relative flex flex-row items-center">
          <div className="mr-3 text-xs leading-6">
            <label
              htmlFor={"gifting" + list.root}
              className="font-medium text-gray-500 flex flex-row items-center"
            >
              Is this a gift?
            </label>
          </div>
          <div className="flex h-6 items-center mr-1">
            <input
              id={"gifting" + list.root}
              aria-describedby="comments-description"
              name={"gifting" + list.root}
              type="checkbox"
              className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
              checked={giftAddress.gifting}
              onChange={e =>
                setGiftAddress(prev => {
                  return { ...prev, gifting: e.target.checked };
                })
              }
            />
          </div>
          {/* <img src={hand1} width={'20px'}  alt='logo'/> */}
        </div>
      </fieldset>
      <fieldset className="flex flex-row justify-end pr-1">
        <div className="relative flex flex-row items-center">
          <div className="mr-3 text-xs leading-6">
            <label
              htmlFor={"gifting" + list.root}
              className="font-medium text-gray-500 flex flex-row items-center"
            ></label>
          </div>
          <div className="flex h-6 items-center">
            <img src={hand1} width={"20px"} alt="logo" />
          </div>
        </div>
      </fieldset>

      {giftAddress.gifting ? (
        <>
          <div className="relative mt-2 shadow-sm">
            <input
              type="gift-address"
              name="gift-address"
              id="gift-address"
              className={`bg-neutral-700 block w-full border-0 font-mono py-3 pr-10 pl-3 text-neutral-200 ${
                !giftAddress.isValid ? "ring-red-300 ring-1 ring-inset" : ""
              } placeholder:text-neutral-600 sm:text-sm sm:leading-6 focus-ring-1 focus-neutral-500`}
              placeholder="y2kids.eth (or 0xabc...)"
              value={giftAddress.address}
              // onChange={evt =>
              //   setGiftAddress(_prev => {
              //     const _isValid = validateAddressIs0xOrEns(evt.target.value);

              //     return {
              //       ..._prev,
              //       isValid: _isValid,
              //       address: evt.target.value,
              //     };
              //   })
              // }
              onChange={evt => handleInputChange(evt.target.value)}
              aria-invalid="true"
              aria-describedby="gift-address-error"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {!giftAddress.isValid ? (
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              ) : (
                <GiftIcon className="h-5 w-5 text-primary" />
              )}
            </div>
          </div>
          {!giftAddress.isValid ? (
            <p
              className="mt-3 ml-2 text-sm text-red-400"
              id="gift-address-error"
            >
              Please enter a valid ENS or account address.
            </p>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
// const mapStateToProps = (state) => ({
//   mintQuantity: state.mint.mintQuantity,
//   // other props if needed...
// });
// const mapStateToProps = (state) => {
//   return ({
//     count: state.count
//   })
// };

// const mapDispatchToProps = {
//   setMintQuantity,
//   // other actions if needed...
// };
// export default connect(mapStateToProps, {increaseCount,decreaseCount})(MintSection);

export const ERC20Balance = ({ library, collection, list, account }) => {
  const [balance, setBalance] = useState("");

  useEffect(() => {
    (async function fetchOnchainTokenData() {
      try {
        if (!account) {
          return;
        }
        if (!library) {
          return;
        }
        if (collection.contract_version < 40) {
          return;
        }

        const getNftContract = address => {
          if (!address) {
            return null;
          }
          const archetypeAbi = require("components/abi/Erc20.json");

          const contract = new ethers.Contract(address, archetypeAbi, library);

          return contract;
        };

        const nftContract = getNftContract(list.currencyAddress);

        if (!nftContract) {
          return;
        }

        const balance = await nftContract.balanceOf(account);

        setBalance(ethers.utils.formatUnits(balance.toString(), list.decimals));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [
    account,
    collection.contract_version,
    library,
    list,
    list.currencyAddress,
    list.decimals,
  ]);

  return balance.length ? (
    <div className="text-xs text-gray-600 ml-2">
      Your {list.symbol} balance: {balance}
    </div>
  ) : null;
};

export const AffiliateCodeMessage = ({ affiliateId, affiliateData }) => {
  return affiliateData && affiliateId && affiliateId.length === 24 ? (
    <div className="text-xs text-gray-600 ml-2 mt-3">
      Minting with affiliate code:{" "}
      <span className="font-mono">
        <a
          href={`${ETHERSCAN_DOMAIN}/address/${affiliateData?.address}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {affiliateData?.ens?.length ? affiliateData.ens : affiliateId}
        </a>
      </span>
    </div>
  ) : (
    <div className="text-xs text-gray-600 ml-2 mt-3">
      Minting without affiliate code
    </div>
  );
};
