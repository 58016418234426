import { useEffect, useState } from 'react';

export function getAuctionTime(endTime, ended) {
  const timeLeftDate = new Date(endTime).getTime() - new Date().getTime();

  if (ended && timeLeftDate < 0) {
    return 'Ended';
  }

  if (timeLeftDate < 0) {
    return 'Started!';
  }

  const days = Math.floor(timeLeftDate / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeftDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeLeftDate % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeftDate % (1000 * 60)) / 1000);
  const dZero = days < 10 ? '0' : '';
  const hZero = hours < 10 ? '0' : '';
  const mZero = minutes < 10 ? '0' : '';
  const sZero = seconds < 10 ? '0' : '';
  const finalString =
    dZero +
    days +
    ':' +
    hZero +
    hours +
    ':' +
    mZero +
    minutes +
    ':' +
    sZero +
    seconds;
  return finalString;
}

export function TimeLeft({ auctionEnd, ended }) {
  const [timeLeft, setTimeLeft] = useState(getAuctionTime(auctionEnd, ended));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getAuctionTime(auctionEnd, ended));
    }, 999);

    return function cleanup() {
      clearInterval(interval);
    };
  }, [auctionEnd, ended]);

  return <div>{timeLeft}</div>;
}
