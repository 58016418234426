import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { ethers } from 'ethers';
import { ETHERSCAN_DOMAIN } from 'components/constants';
import { Fragment } from 'react';
import { GiftIcon } from '@heroicons/react/20/solid';

export function MintModalV41({
  currentStep,
  setCurrentStep,
  collection,
  modalData,
  walletAddress,
}) {
  const symbol = modalData.symbol || ethers.constants.AddressZero;

  const price = ethers.utils.formatUnits(
    modalData.price,
    modalData.decimals || 18,
  );

  return (
    <Transition.Root show={currentStep > 0} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setCurrentStep(0);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-900 bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 tranneutral-y-4 sm:tranneutral-y-0 sm:scale-95"
              enterTo="opacity-100 tranneutral-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 tranneutral-y-0 sm:scale-100"
              leaveTo="opacity-0 tranneutral-y-4 sm:tranneutral-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-auto relative transform bg-neutral-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:py-8 sm:px-6 max-h-128 min-h-72">
                {currentStep === 1 && (
                  <>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-200 animate-spin">
                      <ArrowPathIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-normal tracking-wide leading-6 text-neutral-300"
                      >
                        Minting{' '}
                        <span className="font-bold text-primary">
                          {modalData.quantity} NFT
                          {(modalData.quantity || 1) > 1 ? 's' : ''}
                        </span>{' '}
                        {!modalData.isBatch ? (
                          <span>
                            for{' '}
                            <span className="font-bold text-primary">
                              {price} {symbol}
                            </span>
                          </span>
                        ) : null}
                      </Dialog.Title>
                      <div className="flex items-center mx-auto text-center justify-center text-sm group mt-2 mb-3 pt-2">
                        {!modalData.txnHash?.length ? (
                          <div className="text-gray-400">
                            Awaiting Metamask confirmation…
                          </div>
                        ) : (
                          <>
                            <a
                              href={`${ETHERSCAN_DOMAIN}/tx/${modalData.txnHash}`}
                              target="_blank"
                              rel="noreferrer"
                              className="underline group-hover:no-underline text-sky-300"
                            >
                              View Transaction
                            </a>
                            <ArrowTopRightOnSquareIcon
                              className="ml-1 h-4 w-4 text-neutral-500 group-hover:text-neutral-600 group-hover:tranneutral-x-1 transition-all"
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </div>

                      <div className="mt-8 mb-4">
                        <div className="text-sm text-gray-500">
                          {modalData.giftAddress?.gifting ? (
                            <>
                              <div className="flex flex-row space-x-2 items-center justify-center">
                                <GiftIcon className="h-4 w-4 text-primary" />
                                <span>Gifting to wallet:</span>
                              </div>
                              <div className="mt-2 font-mono text-gray-300">
                                {modalData.giftAddress.address}
                              </div>
                            </>
                          ) : (
                            <>
                              <span>Minting to wallet:</span>
                              <div className="mt-2 font-mono text-gray-300">
                                {walletAddress}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 animate-spin">
                      <ArrowPathIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-bold tracking-wide leading-6 text-white"
                      >
                        Approve {symbol} spending
                      </Dialog.Title>

                      <div className="flex items-center mx-auto text-center justify-center text-sm text-sky-300 group mt-2 mb-3 pt-2 w-full">
                        {!modalData.txnHash?.length ? (
                          <div className="text-gray-400">
                            Awaiting approval confirmation…
                          </div>
                        ) : (
                          <div className="text-sky-300 flex flex-row">
                            <a
                              href={`${ETHERSCAN_DOMAIN}/tx/${modalData.txnHash}`}
                              target="_blank"
                              rel="noreferrer"
                              className="underline group-hover:no-underline"
                            >
                              View Approval Transaction
                            </a>{' '}
                            <ArrowTopRightOnSquareIcon
                              className="ml-1 h-4 w-4 text-neutral-500 group-hover:text-neutral-600 group-hover:tranneutral-x-1 transition-all"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>

                      <div className="mt-8 mb-4">
                        <p className="text-sm text-gray-500">
                          {modalData.collectionSymbol} contract is requesting
                          permission to spend your {symbol}.
                        </p>
                        {modalData.allowance ? (
                          <div className="text-sm text-gray-500 mt-4 space-y-1">
                            <p>
                              <span className="text-gray-400">Price:</span>{' '}
                              {price} {symbol}
                            </p>
                            <p>
                              <span className="text-gray-400">
                                Your Allowance:
                              </span>{' '}
                              <span className="text-red-600">
                                {ethers.utils.formatUnits(
                                  modalData.allowance,
                                  modalData.decimals || 18,
                                )}{' '}
                                {symbol}
                              </span>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-bold tracking-wide leading-6 text-white"
                      >
                        Success!
                      </Dialog.Title>
                      <div className="flex items-center mx-auto text-center justify-center text-sm text-sky-300 group mt-2 mb-3">
                        <a
                          href={`${ETHERSCAN_DOMAIN}/tx/${modalData.txnHash}`}
                          target="_blank"
                          rel="noreferrer"
                          className="underline group-hover:no-underline"
                        >
                          View Transaction
                        </a>{' '}
                        <ArrowTopRightOnSquareIcon
                          className="ml-1 h-4 w-4 text-neutral-500 group-hover:text-neutral-600 group-hover:tranneutral-x-1 transition-all"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-8 mb-4">
                        {collection?.contract_type !== 'ERC1155' ? (
                          <>
                            {' '}
                            <p className="text-sm text-gray-500">
                              Token
                              {(modalData.tokenIds?.length || 1) > 1
                                ? 's'
                                : ''}{' '}
                              minted:
                            </p>
                            <div className="mt-3 space-y-2">
                              {modalData.tokenIds?.map(id => (
                                <p key={id} className="">
                                  <a
                                    href={`/${collection?.slug}/${id}`}
                                    className="underline hover:no-underline text-sky-200"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {collection?.name} #{id}
                                  </a>
                                </p>
                              ))}
                            </div>
                          </>
                        ) : (
                          <p className="text-sm text-gray-500">
                            {modalData.quantity} Token
                            {modalData.quantity > 1 ? 's' : ''} minted
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
