import { ethers } from 'ethers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useAccount, useEnsName } from 'wagmi';

import { WindowDropDowns } from 'components';
// import folder from 'assets/windowsIcons/318(48x48).png';
import {
  completeSaleApi,
  getAffiliateData,
  getCollectionSlim,
  getEligibleLists,
  getInviteListsTruncated,
} from 'components/clientApi';
import {
  COLLECTION_ADDRESS,
  ETHERSCAN_DOMAIN,
  TOAST_CONFIG,
} from 'components/constants';
// import { Progress } from 'components/ui/progress';
import {
  checkERC20Allowances,
  computeUnitPrice,
  getNftContract,
  getProof,
  getValueBN,
  isPublicList,
  shortenAddress,
  useEthersProvider,
  useEthersSigner,
} from 'components/utils';

import etherscan2 from '../../../assets/etherscan2.png';
import { MintModalV41 } from './MintModalV41';
import { ERC20Balance, MintPriceCalculation, MintSection } from './MintSection';
import dropDownData from './dropDownData';
// import star1 from '../../../assets/star1.png'
// import lock1 from '../../../assets/lock1.png'
// import unlock1 from '../../../assets/unlock1.png'
// import hand1 from '../../../assets/hand1.png'
// import heart1 from '../../../assets/heart1.png'
// import info1 from '../../../assets/info1.png'
// import  { FaqsBox}  from '../index';
// import Modal from '../../Modal'

export default function Mint({ onClose }) {
  const [docText, setDocText] = useState('');
  const [wordWrap, setWordWrap] = useState(false);

  // const [showFaqs, setShowFaqs] = useState(false);

  function onClickOptionItem(item) {
    switch (item) {
      case 'Exit':
        onClose();
        break;
      case 'Word Wrap':
        setWordWrap(!wordWrap);
        break;
      case 'Time/Date': {
        const date = new Date();
        setDocText(
          `${docText}${date.toLocaleTimeString()} ${date.toLocaleDateString()}`,
        );
        break;
      }
      default:
    }
  }

  const { address: account } = useAccount();

  const signer = useEthersSigner();
  const provider = useEthersProvider();

  const { data: web3Ens } = useEnsName({ account });

  let userAddress = account;

  // const router = useRouter();
  // const query = router.query;
  // const affiliateIdRef = useRef(query.ref);

  let [searchParams] = useSearchParams();
  const affiliateIdRef = useRef(searchParams.get('ref'));

  // affiliateIdRef.current = query.ref;
  const [collection, setCollection] = useState();
  const [affiliateId, setAffiliateId] = useState();

  useEffect(() => {
    console.log('set aff id');
    if (!collection?.token_address) {
      return;
    }
    const _affiliateId =
      (Boolean(global.window?.localStorage) &&
        localStorage?.getItem(collection.token_address)) ||
      '';
    setAffiliateId(_affiliateId);
  }, [collection?.token_address]);

  const [affiliateData, setAffiliateData] = useState(null);

  const [affiliateFee, setAffiliateFee] = useState(0);

  const [listStatus, setListStatus] = useState({
    type: 'loading',
  });

  const [nftContract, setNftContract] = useState();

  // const [config, setConfig] = useState();

  const [activeInviteLists, setActiveInviteLists] = useState([]);

  // const [numOwned, setNumOwned] = useState('?');
  const [discounts, setDiscounts] = useState({
    affiliateDiscount: 0,
    mintTiers: [],
  });

  const [currentStep, setCurrentStep] = useState(0);

  const [modalData, setModalData] = useState({
    txnHash: '',
    tokenIds: [],
    quantity: 0,
    price: ethers.BigNumber.from(0),
    currencyAddress: ethers.constants.AddressZero,
    giftAddress: {
      address: '',
      gifting: false,
      isValid: false,
    },
  });

  useEffect(() => {
    (async function fetchCollectionData() {
      console.log('fetching collection');
      try {
        const collection = await getCollectionSlim(COLLECTION_ADDRESS);

        console.log({ collection });

        setCollection(collection);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async function initNftContracts() {
      console.log('initing contracts');
      try {
        if (!signer || !COLLECTION_ADDRESS) {
          return;
        }

        const nftContract = getNftContract({
          collectionAddress: COLLECTION_ADDRESS,
          provider,
          signer,
        });

        setNftContract(nftContract);

        const config = await nftContract.config();

        // setConfig(config);

        const _affiliateFee = config.affiliateFee;

        setAffiliateFee(_affiliateFee);

        const discounts = config.discounts;

        if (!discounts) {
          return;
        }

        const discountFormatted = {
          affiliateDiscount: discounts.affiliateDiscount,
          mintTiers: discounts.mintTiers.map(tier => ({
            numMints: tier.numMints,
            mintDiscount: tier.mintDiscount,
          })),
        };

        setDiscounts(discountFormatted);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [signer, collection, provider, affiliateFee]);

  useEffect(() => {
    (async function _fetchAffiliateSignature() {
      console.log('inside the fetch aff signature hook');

      function getEns(dbEns, web3Ens) {
        if (!web3Ens?.length) {
          return {};
        }
        return web3Ens ? { ens: web3Ens } : { ens: dbEns };
      }

      const localStorageKey = COLLECTION_ADDRESS; // Use contract address as the key
      const localStorageAffiliateId = localStorage.getItem(localStorageKey);

      if (!affiliateIdRef.current && localStorageAffiliateId) {
        affiliateIdRef.current = localStorageAffiliateId;
      }

      let affiliateId = affiliateIdRef.current;

      console.log({ affiliateIdRef, affiliateId });

      if (!affiliateId) {
        return;
      }

      if (affiliateId.length > 0 && affiliateId.length !== 24) {
        console.log(affiliateId, ' is an invalid referral code');
        console.log({ toast });
        toast('Invalid referral code.', TOAST_CONFIG);
        return;
      }

      try {
        let affiliateDataRes = await getAffiliateData([
          'affiliateId',
          affiliateId,
        ]);

        console.log({ affiliateDataRes });

        if (!affiliateDataRes) {
          toast('Invalid affiliate', TOAST_CONFIG);
          console.log("That's not a valid affiliate for this collection.");
          return;
        }

        const affiliateAddress = ethers.utils.getAddress(
          affiliateDataRes.address,
        );

        // prevent user from minting with their own affiliate code
        if (
          affiliateAddress === ethers.utils.getAddress(account || '') ||
          affiliateAddress === ethers.utils.getAddress(userAddress)
        ) {
          console.error(
            "You're not allowed to mint with your own affiliate code.",
          );
          toast("You can't mint with your own affiliate code.", TOAST_CONFIG);
          affiliateId = localStorageAffiliateId;

          if (affiliateId) {
            affiliateDataRes = await getAffiliateData([
              'affiliateId',
              affiliateId,
            ]);
          } else {
            return;
          }
        }

        if (affiliateDataRes) {
          console.log({ affiliateDataRes });

          setAffiliateData({
            ...affiliateDataRes,
            ...getEns(affiliateDataRes.ens, web3Ens),
          });

          // Store affiliateId in localStorage with contract address as the key
          localStorage.setItem(localStorageKey, affiliateId);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [account, userAddress, web3Ens]);

  const setNumMinted = useCallback(
    async lists => {
      const _lists = [...lists];

      if (!nftContract) {
        return;
      }

      for (const list of _lists) {
        const numMinted = await nftContract.minted(userAddress, list.root);

        list.numMinted = numMinted.toString();

        const numMintedOnList = await nftContract.listSupply(list.root);

        list.numMintedOnList = numMintedOnList.toString();

        setActiveInviteLists(_lists);
      }

      setListStatus({ type: 'done' });

      // const balance = await nftContract.balanceOf(userAddress);
      // setNumOwned(balance.toString());
    },
    [nftContract, userAddress],
  );

  useEffect(() => {
    async function fetchInviteLists() {
      if (!collection) {
        return;
      }
      console.log('fetching lists');
      try {
        setListStatus({ type: 'loading' });

        if (!userAddress) {
          let inviteListsRes = await getInviteListsTruncated(
            collection.token_address,
          );

          console.log({ inviteListsRes });

          if (!inviteListsRes || !inviteListsRes.length) {
            setListStatus({ type: 'done' });
            return;
          }

          const publicSales = inviteListsRes
            .filter(list => list.limit > 0)
            .filter(list => {
              return isPublicList(list.root);
            });

          if (publicSales.length) {
            setActiveInviteLists(publicSales);
          }

          setListStatus({ type: 'done' });
          return;
        }

        const eligibleListsRes = await getEligibleLists(
          collection.token_address,
          ethers.utils.getAddress(userAddress),
        );
        // console.log({ eligibleListsRes });

        if (!eligibleListsRes || !eligibleListsRes.length) {
          setListStatus({ type: 'done' });
          return;
        }

        setActiveInviteLists(eligibleListsRes);
        setListStatus({ type: 'done' });

        setNumMinted(eligibleListsRes);
      } catch (err) {
        console.error(err);
      }
    }

    fetchInviteLists();
  }, [userAddress, setNumMinted, collection]);

  const handleMintClick = ({
    invite,
    mintQuantity,
    computeUnitPrice,
    giftAddress,
  }) => async evt => {
    if (evt && evt.cancelable) evt.preventDefault();

    // if (isWrongNetwork) {
    //   toast(
    //     `Switch your Metamask network to ${networkDisplayNames[NETWORK]} first.`,
    //     getToastConfig(CSS_PINK)
    //   );
    //   return;
    // }

    if (giftAddress.gifting && !giftAddress.isValid) {
      toast(
        `${
          giftAddress.address.length > 24
            ? shortenAddress(giftAddress.address)
            : giftAddress.address
        } is not a valid gift address. Fix it or disable the gift option.`,
        TOAST_CONFIG,
      );
      return;
    }

    console.log({ nftContract, provider });
    //
    // console.log({ invite, mintQuantity });
    if (!nftContract || !provider) {
      const _nftContract = getNftContract({
        collectionAddress: COLLECTION_ADDRESS,
        provider,
        signer,
      });

      setNftContract(_nftContract);

      // toast("Contract error. Are you logged in to Metamask?", TOAST_CONFIG);
      // return;
    }

    if (!account) {
      return toast('Connect your wallet to mint.', TOAST_CONFIG);
    }

    if (mintQuantity <= 0) {
      return toast(
        'Yeah, try minting a number higher than zero.',
        TOAST_CONFIG,
      );
    }

    try {
      if (!collection) {
        return;
      }

      let valueBN, priceBN, quantityBN, unitSize, totalBN;

      ({ valueBN, priceBN, quantityBN, unitSize, totalBN } = getValueBN(
        invite,
        mintQuantity,
        affiliateIdRef.current || '',
        computeUnitPrice,
      ));

      console.log({ valueBN });

      if (!priceBN) {
        throw new Error('No price available.');
      }

      setModalData({
        txnHash: '',
        tokenIds: [],
        quantity: mintQuantity * unitSize,
        decimals: invite.decimals,
        symbol: invite.symbol,
        price:
          invite.currencyAddress === ethers.constants.AddressZero
            ? valueBN
            : priceBN.mul(quantityBN).mul(unitSize),
        currencyAddress: invite.currencyAddress,
        giftAddress,
      });

      setCurrentStep(1);

      let txn;

      console.log('getting proof');

      const proof = await getProof({
        collection,
        invite,
        userAddress: account,
      });

      const authObject = { key: invite.root, proof };

      const affiliateAddress = affiliateData
        ? ethers.utils.getAddress(affiliateData.address)
        : ethers.constants.AddressZero;

      const affiliateSignature = affiliateData ? affiliateData.signature : '0x';

      await checkERC20Allowances({
        invite,
        account,
        setCurrentStep,
        setModalData,
        library: provider,
        collection,
        totalBN,
        signer,
      });

      if (!nftContract || !provider) {
        console.log({ nftContract, provider });
        toast('Contract error. Are you logged in to Metamask?', TOAST_CONFIG);
        return;
      }

      let estimate;

      if (giftAddress.gifting && collection.contract_version >= 30) {
        estimate = await nftContract.estimateGas.mintTo(
          authObject,
          mintQuantity,
          giftAddress.address,
          affiliateAddress,
          affiliateSignature,
          { value: valueBN },
        );

        console.log({ estimate });

        txn = await nftContract.mintTo(
          authObject,
          mintQuantity,
          giftAddress.address,
          affiliateAddress,
          affiliateSignature,
          {
            value: valueBN,
          },
        );
      } else {
        console.log({
          authObject,
          mintQuantity,
          affiliateAddress,
          affiliateSignature,
          value: valueBN.toString(),
        });

        estimate = await nftContract.estimateGas.mint(
          authObject,
          mintQuantity,
          affiliateAddress,
          affiliateSignature,
          { value: valueBN },
        );

        console.log({ estimate: estimate.toString() });

        txn = await nftContract.mint(
          authObject,
          mintQuantity,
          affiliateAddress,
          affiliateSignature,
          {
            value: valueBN,
          },
        );
      }

      setModalData(prev => ({
        ...prev,
        txnHash: txn.hash,
      }));

      console.log({ txn });

      if (!txn || !txn.hash) {
        return;
      }

      const receipt = await txn?.wait();

      console.log({ receipt });

      const transferEvents = receipt.events.filter(
        evt => evt.event === 'Transfer' && evt.args[1] === account,
      );

      console.log({ transferEvents });

      const tokenIds = transferEvents.map(evt => evt.args.tokenId.toString());

      console.log({ tokenIds });

      setModalData(prev => ({
        ...prev,
        tokenIds,
        txnHash: receipt.transactionHash,
      }));

      setNumMinted([...activeInviteLists]);
      setCurrentStep(3);

      for (const transferEvent of transferEvents) {
        const { tokenId, from, to } = transferEvent.args;

        await completeSaleApi({
          value: priceBN.toString() || '',
          value_decimal: parseFloat(
            ethers.utils.formatUnits(priceBN, invite.decimals),
          ),
          token_address: collection.token_address,
          token_id: tokenId.toString(),
          salt: undefined,
          signature: undefined,
          buyer_address: to,
          seller_address: from,
          transaction_hash: receipt.transactionHash,
          block_hash: receipt.blockHash,
          block_number: receipt.blockNumber,
          sale_type: 'MINT',
        });
      }
    } catch (err) {
      console.error(err);
      setCurrentStep(0);

      if (err.code === 4001 || err.code === 'ACTION_REJECTED') {
        toast('Please try again and confirm the transaction.', TOAST_CONFIG);
      } else {
        let error = String(err);
        if (err.message) {
          const code = err.code ? err.code + ': ' : '';
          error = code + err.message;
        }

        if (error.includes('insufficient')) {
          toast("You don't have enough ETH!", TOAST_CONFIG);
        } else {
          console.error(err);
          // const decodedError = decodeError(
          //   err,
          //   collection.contract_version,
          //   collection
          // );
          // toast(`Mint failed: ${decodedError}`, getToastConfig(CSS_PINK));
          toast(`Mint failed: ${err}`, TOAST_CONFIG);
        }
      }
    }
  };

  if (!collection) {
    return null;
  }

  return (
    <Div>
      <MintModalV41
        {...{
          currentStep,
          setCurrentStep,
          modalData,
          collection,
          walletAddress: userAddress,
        }}
      />

      <section className="np__toolbar">
        <WindowDropDowns items={dropDownData} onClickItem={onClickOptionItem} />
      </section>
      <MintContainer>
        <div className="com__content__right">
          <section className="flex flex-row">
            <div className="com__content__right__card">
              <div className="com__content__right__card__header">
                {/* Files Stored on This Computer */}
                {/* <span className='flex'>

              Private <img src={star1} width={'18px'} alt='logo' className='ml-1'/>
              </span> */}
                <span className="my-2">&nbsp;</span>
              </div>
              <div className="mint-content">
                <div className="flex font-secondary ">
                  <span className="text-neutral-500 tracking-tight">
                    Contract:&nbsp;{' '}
                  </span>{' '}
                  <span>
                    <a
                      rel="noreferrer noopener"
                      target="_blank"
                      href={`${ETHERSCAN_DOMAIN}/address/${COLLECTION_ADDRESS}`}
                      className="text-xs flex"
                    >
                      Etherscan
                      <img src={etherscan2} width={'20px'} alt="logo" />
                    </a>
                  </span>
                </div>

                <div>
                  {listStatus.type === 'done'
                    ? activeInviteLists.map(list => {
                        return (
                          <MintSection
                            key={list.root}
                            list={list}
                            max_items={collection.max_items}
                            affiliateId={affiliateId}
                            affiliateData={affiliateData}
                            handleMintClick={handleMintClick}
                            computeUnitPrice={computeUnitPrice(discounts)}
                            affiliateFee={affiliateFee}
                            erc20BalanceSection={
                              <ERC20Balance
                                {...{
                                  provider,
                                  collection,
                                  list,
                                  account,
                                }}
                              />
                            }
                            mintPriceCalculator={mintQuantity => {
                              return (
                                <MintPriceCalculation
                                  list={list}
                                  mintQuantity={mintQuantity}
                                  price={computeUnitPrice(discounts)(
                                    mintQuantity,
                                    affiliateId,
                                    list,
                                  )}
                                />
                              );
                            }}
                          />
                        );
                      })
                    : null}
                </div>

                <div>
                  <div className="flex space-x-3">
                    <div className="">
                      {/* <div
                      className="py-3  text-normal bg-quaternary text-blue-700 flex items-center justify-start font-bold tracking-wide font-secondary cursor-not-allowed"
                    >
                      MINTING NOW
                    </div> */}
                    </div>
                    <div className="mt-3 text-xs mx-auto text-blue-600">
                      {/* {collection.num_items} / {collection.max_items} */}0 /{' '}
                      {collection.max_items}
                    </div>
                  </div>

                  {/* <div className="mt-6 flex flex-col align-center"> */}
                  {/* // todo: make this dynamic */}
                  {/* <Progress
                    value={`${(collection.num_items / collection.max_items) *
                      100}`}
                  /> */}
                  {/* </div> */}
                  <div>
                    {/* <div
        className="mt-6 flex flex-row hover:cursor-pointer"
        onClick={() => setShowFaqs(!showFaqs)}
      > */}
                    {/* // todo: make this dynamic */}
                    {/* <Progress
          value={`${(collection.num_items / collection.max_items) * 100}`}
        /> */}
                    {/* <img src={info1} width={'25px'} alt='logo' />
        <p className='ml-1'>
          FAQS
        </p>
      </div>
      {showFaqs && <FaqsBox />}*/}
                  </div>
                </div>
              </div>
            </div>
            <section className="ml-4 mt-8 flex flex-col">
              <div className="flex">
                <button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50 rounded-md">
                  Milady
                </button>
                <p className="text-blue-700 m-3.5">0/190</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Remilio
                </button>
                <p className="text-blue-700 m-3.5">0/160</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-4 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Sproto Gremlins
                </button>
                <p className="text-blue-700 m-3.5">0/150</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-4 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Sprotoladys
                </button>
                <p className="text-blue-700 m-3.5">0/150</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-4 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Angelic Labs
                </button>
                <p className="text-blue-700 m-3.5">0/130</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  YAYO
                </button>
                <p className="text-blue-700 m-3.5">0/90</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Pixelady
                </button>
                <p className="text-blue-700 m-3.5">0/75</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Radbro
                </button>
                <p className="text-blue-700 m-3.5">0/70</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Kawamii
                </button>
                <p className="text-blue-700 m-3.5">0/66</p>
              </div>
              <div className="flex">
                <button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50  rounded-md">
                  Milarfy
                </button>
                <p className="text-blue-700 m-3.5">0/30</p>
              </div>
            </section>
          </section>
        </div>
      </MintContainer>
    </Div>
  );
}

const Div = styled.div`
  height: 100%;
  background: linear-gradient(to right, #ddddc9 0%, #ede8cd 100%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }
`;

const MintContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  font-size: 12px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: linear-gradient(to right, #ddddc9 0%, #ede8cd 100%);
  .com__toolbar {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 100%;
    height: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    flex-shrink: 0;
  }
  .com__options {
    height: 23px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1px 0 1px 2px;
    border-left: 0;
    flex: 1;
  }
  .com__windows-logo {
    height: 100%;
    border-left: 1px solid white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .com__function_bar {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 11px;
    padding: 1px 3px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
  }
  .com__function_bar__button {
    display: flex;
    height: 100%;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 3px;
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
    }
    &:hover:active {
      border: 1px solid rgb(185, 185, 185);
      background-color: #dedede;
      box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.7);
      color: rgba(255, 255, 255, 0.7);
      & > * {
        transform: translate(1px, 1px);
      }
    }
  }
  .com__function_bar__button--disable {
    filter: grayscale(1);
    opacity: 0.7;
    display: flex;
    height: 100%;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
  }
  .com__function_bar__text {
    margin-right: 4px;
  }
  .com__function_bar__icon {
    height: 30px;
    width: 30px;
    &--normalize {
      height: 22px;
      width: 22px;
      margin: 0 4px 0 1px;
    }
    &--margin12 {
      height: 22px;
      width: 22px;
      margin: 0 1px 0 2px;
    }
    &--margin-1 {
      margin: 0 -1px;
      height: 30px;
      width: 30px;
    }
  }
  .com__function_bar__separate {
    height: 90%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0 2px;
  }
  .com__function_bar__arrow {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 4px;
    &:before {
      content: '';
      display: block;
      border-width: 3px 3px 0;
      border-color: #000 transparent;
      border-style: solid;
    }
  }
  .com__function_bar__arrow--margin-11 {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 1px 0 -1px;
    &:before {
      content: '';
      display: block;
      border-width: 3px 3px 0;
      border-color: #000 transparent;
      border-style: solid;
    }
  }
  .com__address_bar {
    flex-shrink: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    height: 20px;
    font-size: 11px;
    display: flex;
    align-items: center;
    padding: 0 2px;
    box-shadow: inset 0 -2px 3px -1px #b0b0b0;
  }
  .com__address_bar__title {
    line-height: 100%;
    color: rgba(0, 0, 0, 0.5);
    padding: 5px;
  }
  .com__address_bar__content {
    border: rgba(122, 122, 255, 0.6) 1px solid;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    background-color: white;
    position: relative;
    &__img {
      width: 14px;
      height: 14px;
    }
    &__img:last-child {
      width: 15px;
      height: 15px;
      right: 1px;
      position: absolute;
    }
    &__img:last-child:hover {
      filter: brightness(1.1);
    }
    &__text {
      white-space: nowrap;
      position: absolute;
      white-space: nowrap;
      left: 16px;
      right: 17px;
    }
  }

  .com__address_bar__go {
    display: flex;
    align-items: center;
    padding: 0 18px 0 5px;
    height: 100%;
    position: relative;
    &__img {
      height: 95%;
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin-right: 3px;
    }
  }
  .com__address_bar__links {
    display: flex;
    align-items: center;
    padding: 0 18px 0 5px;
    height: 100%;
    position: relative;
    &__img {
      position: absolute;
      right: 2px;
      top: 3px;
      height: 5px;
      width: 8px;
    }
    &__text {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .com__address_bar__separate {
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0 rgba(255, 255, 255, 0.7);
  }
  .com__content {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-top-width: 0;
    background-color: #f1f1f1;
    overflow: auto;
    font-size: 11px;
    position: relative;
  }
  .com__content__inner {
    display: flex;
    height: 100%;
    overflow: auto;
  }
  .com__content__left {
    width: 180px;
    height: 100%;
    background: linear-gradient(to bottom, #748aff 0%, #4057d3 100%);
    overflow: auto;
    padding: 10px;
  }

  .com__content__left__card {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 100%;
    overflow: hidden;
  }
  .com__content__left__card:not(:last-child) {
    margin-bottom: 12px;
  }
  .com__content__left__card__header {
    display: flex;
    align-items: center;
    height: 23px;
    padding-left: 11px;
    padding-right: 2px;
    cursor: pointer;
    background: linear-gradient(
      to right,
      rgb(240, 240, 255) 0,
      rgb(240, 240, 255) 30%,
      rgb(168, 188, 255) 100%
    );
  }
  .com__content__left__card__header:hover {
    & .com__content__left__card__header__text {
      color: #1c68ff;
    }
  }
  .com__content__left__card__header__text {
    font-weight: 700;
    color: #0c327d;
    flex: 1;
  }
  .com__content__left__card__header__img {
    width: 18px;
    height: 18px;
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  }
  .com__content__left__card__content {
    padding: 5px 10px;
    background: linear-gradient(
      to right,
      rgb(180, 200, 251) 0%,
      rgb(164, 185, 251) 50%,
      rgb(180, 200, 251) 100%
    );
    background-color: rgba(198, 211, 255, 0.87);
  }
  .com__content__left__card__row {
    display: flex;
    margin-bottom: 2px;
  }

  .com__content__left__card__img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  .com__content__left__card__text {
    font-size: 10px;
    line-height: 14px;
    color: #0c327d;
    &.black {
      color: #000;
    }
    &.bold {
      font-weight: bold;
    }

    &.link:hover {
      cursor: pointer;
      color: #2b72ff;
      text-decoration: underline;
    }
  }
  .com__content__right {
    height: 100%;
    overflow: auto;
    background: linear-gradient(to right, #eeeede 0%, #ede8cd 100%);
    flex: 1;
  }
  .com__content__right__card__header {
    width: 300px;
    font-weight: 700;
    padding: 2px 0 3px 12px;
    position: relative;
    &:after {
      content: '';
      display: block;
      background: linear-gradient(to right, #70bfff 0, #fff 100%);
      position: absolute;
      bottom: 0;
      left: -12px;
      height: 1px;
      width: 100%;
    }
  }
  .mint-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-right: 0;
    flex-wrap: wrap;
    padding: 15px 15px 0;
  }
  .mint-content > * + * {
    margin-top: 0.4rem;
  }
  .com__content__right__card__content {
    display: flex;
    align-items: center;
    padding-right: 0;
    flex-wrap: wrap;
    padding: 15px 15px 0;
  }
  .com__content__right__card__item {
    display: flex;
    align-items: center;
    width: 200px;
    margin-bottom: 15px;
    height: auto;
  }
  .com__content__right__card__img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  .com__content__right__card__text {
    white-space: nowrap;
    height: 100%;
  }
  .com__content__right__card--me {
    .com__content__right__card__header:after,
    .com__content__right__card__header {
      transition: 0.4s;
    }
    &:hover {
      .com__content__right__card__header:after {
        width: 0;
      }
      .com__content__right__card__header {
        transform: scale(1.2) translate(20px, 5px);
      }
    }
  }
  .com__content__right__card__item--me {
    display: flex;
    align-items: center;
    width: 200px;
    margin-bottom: 15px;
    height: auto;
    & > * {
      transition: transform 0.2s;
    }
    &:hover .com__content__right__card__img {
      transform: rotate(-10deg) scale(0.9);
    }
    &:hover .com__content__right__card__text {
      transform: scale(1.2);
      transition-timing-function: cubic-bezier(0.23, 1.93, 0.59, -0.15);
    }
  }
`;
