import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'assets/clear.css';
import 'assets/font.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LoginProvider } from './LoginContext';

import store from "./redux/store";
import { Provider } from "react-redux";
ReactDOM.render(
  <React.StrictMode>
       <Provider store={store}>
         <BrowserRouter>
          <LoginProvider>
            <App /> 
           </LoginProvider>
          </BrowserRouter>
       </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register();

if (module.hot && !window.frameElement) {
  console.log('HMR enabled');
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}
