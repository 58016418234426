import { TimeLeft } from './TimeLeft';
import { LoadingSpinner } from 'components/LoadingSpinner';
// import unlock1 from '../../assets/unlock1.png'
import lock1 from '../../assets/lock1.png'
export function TimeLeftInviteList({ list, startTimePassed }) {
  const isListEnded = end => {
    return new Date(end).getTime() - new Date().getTime() < 0;
  };

  return (
    <div className="block text-xs font-medium text-gray-400 mr-2 grow text-right">
      {startTimePassed ? (
        <>
          {list.end && new Date(list.end).getUTCFullYear() !== 2106 ? (
            <span className="flex flex-row text-right justify-end">
              Limited-time invite: &nbsp;{' '}
              <span
                className={`${
                  isListEnded(list.end) ? 'text-red-500' : 'text-primary'
                } tracking-wide`}
              >
                <TimeLeft auctionEnd={new Date(list.end)} ended={true} />
              </span>
            </span>
          ) : (
            <>
            <span className='flex justify-end'>
              <span className="text-blue-700 ">Status:</span>{' '}
              {/* <span className="text-primary">Live</span> */}
              <img src={lock1} width={'12px'} alt='logo' className='ml-1'/>
            </span>
            </>
          )}
        </>
      ) : (
        <span className="flex flex-row text-right justify-end">
          Live in:&nbsp;{' '}
          {list.start_time ? (
            <span className="text-amber-400 tracking-wide">
              <TimeLeft auctionEnd={new Date(list.start_time)} />
            </span>
          ) : (
            <div className="h-4 w-4">
              <LoadingSpinner />
            </div>
          )}
        </span>
      )}
    </div>
  );
}
