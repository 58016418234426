import { useWeb3Modal } from '@web3modal/wagmi/react';
import { Link } from 'react-router-dom';
import './login.css';
import { useAccount } from 'wagmi';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLogin } from '../LoginContext';
import logo from '../assets/logo.png';
export function Login() {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get('ref');

  const { open } = useWeb3Modal();
  const { loginCheck, setLoginCheck } = useLogin();
  const navigate = useNavigate();

  const { isConnected } = useAccount();

  useEffect(() => {
    if (isConnected) {
      navigate(`/home${ref ? `?ref=${ref}` : ''}`);
    }
  }, [isConnected, navigate, ref]);

  console.log(loginCheck);
  return (
    <div className="login-container">
      <div className="header-login" />
      <main>
        <div className="img">
          <img src={logo} alt="Logo do Windows XP" />
          <h1>To begin, click your user name</h1>
        </div>
        <div className="line"></div>
        <div className="users" style={{ cursor: 'pointer' }}>
          <div onClick={open}>
            <section className="lucas">
              <div className="iconA"></div>
              <div className="user">
                <h3>Administrator</h3>
                {/* <p>Type your password</p>
              <div className="input">
                <input type="password" />
                <button className="green">
                  {' '}
                  <img src="/login/Vector (1).png" alt="" />{' '}
                </button>
                <button className="blue">
                  {' '}
                  <img src="/login/_.png" alt="" />{' '}
                </button>
              </div> */}
              </div>
            </section>
          </div>

          <Link to="/home">
            <section className="crystal" onClick={() => setLoginCheck(true)}>
              <div className="iconB"></div>
              <div className="user">
                <p>Guest</p>
              </div>
            </section>
          </Link>
        </div>
      </main>
      <footer className="footer-login flex flex-row items-end justify-end">
        {/* <div className="btn">
          <button>
            <img src="/login/Group 2.png" alt="" />
          </button>
          <p>Turn off computer</p>
        </div> */}
        {/* <div className="informations self-end justify-self-end">
          <p>After you log on, you can add or change accounts</p>
          <p>Just go to your Control Panel and click User Accounts</p>
        </div> */}
      </footer>
    </div>
  );
}
