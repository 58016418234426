// import React, { useState } from 'react';
import styled from 'styled-components';
import {useState, useEffect} from 'react';
export default function VRMguide({ onClose }) {
  // const [copiedAddress, setCopiedAddress] = useState('');
  const [copiedAddress, setCopiedAddress] = useState('');
  const [copiedAddress2, setCopiedAddress2] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {isMobile ? (
      // Content to render for mobile devices
      <Div>
      <section className='p-3 flex flex-col ' style={{ marginInline:'auto'}}>

      <div className='break-all'>
      1. Sign in with your wallet to verify your eligibility<p className='ml-3'>and claim your Vtubers. Download the file(s).</p>
      </div>
      
      <br/><br/>

      <div className='break-all'>
      2. Download VSeeFace and OBS, tutorial that we used: <br/><p className='ml-3'>https://www.youtube.com/watch?v=oGs2UVMjnms</p>
      <div
            className="font-mono text-sm group ml-2"
            onClick={() => {
              setCopiedAddress(true);
              navigator.clipboard.writeText(
                `https://www.youtube.com/watch?v=oGs2UVMjnms`
              );

              if (!copiedAddress) {
                setTimeout(() => {
                  setCopiedAddress(false);
                }, 3000);
              }
            }}
          >
            <div className="flex align-center mt-0">
              <div className="font-sans font-bold pr-1 text-black group-hover:text-gray-600 cursor-pointer transition-colors" >
                {copiedAddress ? 'Copied!' : 'Copy Link'}
              </div>
              <div className="h-3 w-3 text-black group-hover:text-gray-600 cursor-pointer transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                  />
                </svg>
              </div>
            </div>
          </div>
      </div>
      
      <br/><br/>

      <div className='break-all'>
      3. Use your favorite video editing software to upload clips<br/><p className='ml-3'>into and edit, before using for content</p><p className='ml-3'> and memes on X ! 🙂</p>
      </div>
      
      <br/><br/>

      <div className='break-all'>
      BONUS: Here's the guide we used to change our voices <br/>using AI for different character models: <br/>https://www.youtube.com/watch?v=Y8IxVVQBEpc
      <div
            className="font-mono text-sm group ml-2"
            onClick={() => {
              setCopiedAddress2(true);
              navigator.clipboard.writeText(
                `https://www.youtube.com/watch?v=Y8IxVVQBEpc`
              );

              if (!copiedAddress2) {
                setTimeout(() => {
                  setCopiedAddress2(false);
                }, 3000);
              }
            }}
          >
            <div className="flex align-center mt-0">
              <div className="font-sans font-bold pr-1 text-black group-hover:text-gray-600 cursor-pointer transition-colors" >
                {copiedAddress2 ? 'Copied!' : 'Copy Link'}
              </div>
              <div className="h-3 w-3 text-black group-hover:text-gray-600 cursor-pointer transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                  />
                </svg>
              </div>
            </div>
          </div>
      </div>
      
      <br/><br/>

      <div className='break-all'>
      Remember, these vrm models can be used in many <br/>different applications, these are just the uses we applied them to! <br/>The opportunities are abundant!
      </div>


      
      </section>
    </Div>
    ) : (
      <Div>
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>

      <div className='break-all'>
      1. Sign in with your wallet to verify your eligibility and claim your Vtubers. Download the file(s).
      </div>
      
      <br/><br/>

      <div className='flex flex-row'>
      2. Download VSeeFace and OBS, tutorial that we used: https://www.youtube.com/watch?v=oGs2UVMjnms
      <div
            className="font-mono text-sm group ml-2"
            onClick={() => {
              setCopiedAddress(true);
              navigator.clipboard.writeText(
                `https://www.youtube.com/watch?v=oGs2UVMjnms`
              );

              if (!copiedAddress) {
                setTimeout(() => {
                  setCopiedAddress(false);
                }, 3000);
              }
            }}
          >
            <div className="flex align-center mt-0">
              <div className="font-sans font-bold pr-1 text-black group-hover:text-gray-600 cursor-pointer transition-colors" >
                {copiedAddress ? 'Copied!' : 'Copy Link'}
              </div>
              <div className="h-3 w-3 text-black group-hover:text-gray-600 cursor-pointer transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                  />
                </svg>
              </div>
            </div>
          </div>
      </div>
      
      <br/><br/>

      <div className='break-all'>
      3. Use your favorite video editing software to upload clips into and edit, before using for content and memes on X ! 🙂
      </div>
      
      <br/><br/>

      <div className='flex flex-row'>
      BONUS: Here's the guide we used to change our voices using AI for different character models: https://www.youtube.com/watch?v=Y8IxVVQBEpc
      <div
            className="font-mono text-sm group ml-2"
            onClick={() => {
              setCopiedAddress2(true);
              navigator.clipboard.writeText(
                `https://www.youtube.com/watch?v=Y8IxVVQBEpc`
              );

              if (!copiedAddress2) {
                setTimeout(() => {
                  setCopiedAddress2(false);
                }, 3000);
              }
            }}
          >
            <div className="flex align-center mt-0">
              <div className="font-sans font-bold pr-1 text-black group-hover:text-gray-600 cursor-pointer transition-colors" >
                {copiedAddress2 ? 'Copied!' : 'Copy Link'}
              </div>
              <div className="h-3 w-3 text-black group-hover:text-gray-600 cursor-pointer transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                  />
                </svg>
              </div>
            </div>
          </div>
      </div>
      
      <br/><br/>

      <div className='break-all'>
      Remember, these vrm models can be used in many different applications, these are just the uses we applied them to! The opportunities are abundant!
      </div>


      
      </section>
    </Div>
      
    )}
   
    </>
  );
}

const Div = styled.div`
  height: 100%;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  display: flex;
  overflow:auto;
  flex-direction: column;
  align-items: stretch;
  z-index: 100;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }
`;
