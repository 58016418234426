// from Webamp demo

const album = 'netBloc Vol. 24: tiuqottigeloot';

export const initialTracks = [
  {
    url:
      'https://files.scatter.art/y2kids-music/Nightcore_Around_The_World~La_La_La~Diamond_Eyes_Techno_Trance.mp4',
    duration: 203,
    metaData: {
      title: 'Around The World',
      artist: 'Nightcore ~La La La~Diamond Eyes Remix',
      album,
    },
  },
  {
    url:
      'https://files.scatter.art/y2kids-music/Nightcore_-_Everytime_We_Touch.mp4',
    duration: 161,
    metaData: {
      title: 'Everytime We Touch',
      artist: 'Nightcore',
      album,
    },
  },
  {
    url: 'https://files.scatter.art/y2kids-music/Nightcore_-_Another_Night.mp3',
    duration: 156,
    metaData: {
      title: 'Another Night',
      artist: 'Nightcore',
      album,
    },
  },
  {
    url: 'https://files.scatter.art/y2kids-music/Nightcore_-_Roses_Are_Red.mp3',
    duration: 199,
    metaData: {
      title: 'Roses Are Red',
      artist: 'Nightcore',
      album,
    },
  },
  {
    url:
      'https://files.scatter.art/y2kids-music/Caramella_Girls_-_Caramelldansen.mp3',
    duration: 178,
    metaData: {
      title: 'Caramelldansen',
      artist: 'Caramella Girls',
      album,
    },
  },
  {
    url:
      'https://files.scatter.art/y2kids-music/Nightcore_Classics_-_Dam_Dadi_Doo.mp4',
    duration: 166,
    metaData: {
      title: 'Dam Dadi Doo',
      artist: 'Nightcore Classics',
      album,
    },
  },
  {
    url: 'https://files.scatter.art/y2kids-music/Nightcore_-_Hot.mp3',
    duration: 132,
    metaData: {
      title: 'Hot',
      artist: 'Nightcore',
      album,
    },
  },
];
