// import React, { useState } from 'react';
import styled from 'styled-components';
import {useState, useEffect} from 'react';
import { useAccount} from 'wagmi';
import { Alchemy, Network } from "alchemy-sdk";
import vrm1 from './Bonus_Builder.vrm'
import vrm2 from './Mascot.vrm'
import folder from 'assets/windowsIcons/318(48x48).png';
// import y2k_tokens from 'components/abi/y2k_tokens';
// import y2k_tokens from "components/abi/y2k_tokens.json";
import Web3 from 'web3';
// import {ethers} from "ethers";
import { COLLECTION_ADDRESS } from 'components/constants';
const y2k_tokens = require("./y2k_tokens.json");

export default function Redeem({ onClose }) {
  const [btnProcessed, setBtnProcessed]= useState(false);
  const [mintedCount, setMintedCount]= useState(0);
  const [balanceCount, setBalanceCount]= useState(0);
  const { address: userAddress, isConnected} = useAccount();
  const config = {
    apiKey: "-y5ukp-XGhcf7_vx-YE9Bflk8XWgZWkU",
    network: Network.ETH_MAINNET,
  };
  const alchemy = new Alchemy(config);
  const pkey = isConnected ? userAddress : '';
  const contract =COLLECTION_ADDRESS.toLowerCase();

  const web3 = new Web3(window.ethereum);
  const collectionContract = new web3.eth.Contract(y2k_tokens, COLLECTION_ADDRESS);


  console.log(pkey);
  const [loading, setLoading]=useState(false);
  let count= 0;

    const getTokenBalance = async () => {
      try {
        const result = await collectionContract.methods.balanceOf(pkey).call();
        console.log('Balance Count NFTs:', result);
        setBalanceCount(Number(result));
        console.log('Balance Count NFTs after:', balanceCount);
      } catch (error) {
        console.error('Error fetching Balance Count count:', error);
      }
  }

    const fetchMintedCount = async () => {
      try {
        const res = await alchemy.core.getAssetTransfers({
          fromBlock: '0x0',
          fromAddress: '0x0000000000000000000000000000000000000000',
          // toAddress: '0x6B9179dd15B3A23A06a07F4B50ba90c54A86597B',//y2k minttooor
          // toAddress:"0x5c43B1eD97e52d009611D89b74fA829FE4ac56b1",//alcemy address
          toAddress: pkey,
          excludeZeroValue: true,
          category: ['erc721'],
        });
        for (const events of res.transfers) {
          
          if (events.erc1155Metadata == null) {
            
            if (events.rawContract.address===contract) {
              count++;
            } else {
              console.log('Condition not met. Address:', events.rawContract.address, typeof(events.rawContract.address));
              console.log(contract);
              console.log(COLLECTION_ADDRESS===events.rawContract.address);
            }
            

          } else {
            for (const erc1155 of events.erc1155Metadata) {
              console.log(
                "ERC-1155 Token Minted: ID- ",
                erc1155.tokenId,
                " Contract- ",
                events.rawContract.address
              );
            }
          }
        }
        console.log("no of mints:"+count)
        setMintedCount(count);
        
      } catch (error) {
        console.error('Error fetching minted count:', error);
      }
      finally{
        setLoading(false);
      }
    };


  const handleButtonClick = () => {
    setBtnProcessed(true);
    setLoading(true);
    getTokenBalance();
    fetchMintedCount();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {isMobile ? (
      // Content to render for mobile devices
      !loading ? (isConnected ?(
      <Div>
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>

      {!btnProcessed?(<button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50 rounded-md" onClick={handleButtonClick}>Click to check for Rewards!</button>):('')}
      {(mintedCount || balanceCount) >= 1 ?(<div className=' break-all'>
      <div>Congrats! You've unlocked our first Y2Kids VTuber file!</div>
      <div className='items-center' style={{marginInline:'9rem'}}>
        <a
        href={vrm1}
        download="Bonus_Builder.vrm"
        target="_blank"
        rel="noreferrer"
        style={{marginBlock:'auto', marginInline:'auto'}}
      >
        <img
            src={folder}
            alt="folder icon"
            style={{marginInline: '1.3rem'}}
          />
        <button className='text-blue-700'>Download File</button>
      </a>
      </div>
        <br/><br/>
      {(mintedCount || balanceCount) >= 10 ?(<div className=' break-all'>
      <div style={{marginInline:'3rem'}}>Congrats! You've unlocked our Mascot VTuber File!</div>
      <div className='items-center' style={{marginInline:'9rem'}}>
        <a
        href={vrm2}
        download="Mascot.vrm"
        target="_blank"
        rel="noreferrer"
        style={{marginBlock:'auto', marginInline:'auto'}}
      >
        <img
            src={folder}
            alt="folder icon"
            style={{marginInline: '1.3rem'}}
          />
        <button className='text-blue-700'>Download File</button>
      </a>
      </div>
</div>):
// Content to render for desktop devices
(<div style={{marginInline: '5rem'}}>Mint {10-mintedCount} or Hold {10-balanceCount} to unlock another VRM file!</div>)}

      </div>):(btnProcessed?(<div>You don't qualify yet...</div>):(''))
      }

      </section>
    </Div>
    ):
    (
      <Div>
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>
      <div className=' break-all'>
      Please 'Connect Wallet' to check for rewards!
      </div>
      
      </section>
    </Div>
    )):
  (<Div>
    <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}><div>Loading...</div>
    </section>
    </Div>)
    ) : (
      !loading ?(isConnected?(//connected key check1 begins
      <Div>
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>
      {!btnProcessed?(<button className="flex-1 py-1 px-8 m-2 border border-blue-700 bg-slate-50 rounded-md" onClick={handleButtonClick}>Click to check for Rewards!</button>):('')}
      {(mintedCount || balanceCount) >= 1 ?(<div className=' break-all'>
        <div style={{marginInline: '3rem'}}>Congrats! You've unlocked our first Y2Kids VTuber file!</div>
        <div className='items-center' style={{marginInline:'10rem'}}>
        <a
        href={vrm1}
        download="Bonus_Builder.vrm"
        target="_blank"
        rel="noreferrer"
      >
        <img
            src={folder}
            alt="folder icon"
            style={{marginInline: '1.3rem'}}
          />
        <button className='text-blue-700'>Download File</button>
      </a>
      </div>
        <br/>
      {(mintedCount || balanceCount) >= 10 ?(<div className=' break-all'>
      <div style={{marginInline: '3rem'}}>Congrats! You've unlocked our Mascot VTuber File!</div>
        <div className='items-center' style={{marginInline:'9rem'}}>
        <a
        href={vrm2}
        download="Mascot.vrm"
        target="_blank"
        rel="noreferrer"
      >
        <img
            src={folder}
            alt="folder icon"
            style={{marginInline: '1.3rem'}}
          />
        <button className='text-blue-700'>Download File</button>
      </a>
      </div>
</div>):(<div style={{marginInline: '4rem'}}>Mint {10-mintedCount} or Hold {10-balanceCount} more to unlock another VRM file!</div>)}

      </div>):(btnProcessed?(<div>You don't qualify yet...</div>):(''))
      }
      </section>
    </Div>)//connected key check1 ends
    :
    (//connected key check2 begins
    <Div>
    <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}>
    <div className=' break-all'>
    Please 'Connect Wallet' to check for rewards!
    </div>
    
    </section>
  </Div>)//connected key check2 ends
    ):(<Div>
      <section className='p-3 flex flex-col ' style={{marginBlock:'auto', marginInline:'auto'}}><div>Loading...</div>
      </section>
      </Div>)
      
    )//outer condition ends
  }
   
    </>
  );
}

const Div = styled.div`
  height: 100%;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 100;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }
`;
