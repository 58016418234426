import { API_URL } from 'components/constants';

export const getCollectionSlim = slugOrAddress => {
  return defaultBody(`/api/collection/${slugOrAddress}`, 'GET');
};

export const getAffiliateData = params => {
  return defaultBody('/api/affiliate' + paramArrayToString(params), 'GET');
};

export const fetchReferrals = params => {
  return defaultBody('/api/referrals' + paramArrayToString(params), 'GET');
};

export const addSingleAffiliate = data => {
  return defaultBody('/api/affiliate/single/v2', 'POST', data);
};

export const getInviteListsTruncated = collectionAddress => {
  return defaultBody(
    `/api/collections/${collectionAddress}/invitelists/truncated`,
    'GET',
  );
};

export const getEligibleLists = (collectionAddress, account) => {
  return defaultBody(
    `/api/collections/${collectionAddress}/eligiblelists/${account}`,
    'GET',
  );
};

export const isAffiliate = params => {
  return defaultBody('/api/isAffiliate' + paramArrayToString(params), 'GET');
};

export const fetchMerkleProof = (collectionAddress, root, account, cached) => {
  return defaultBody(
    `/api/collections/${collectionAddress}/root/${root}/account/${account}?cached=${cached ||
      'false'}`,
    'GET',
  );
};

export const topAffiliatesApi = params => {
  return defaultBody('/api/affiliates/top' + paramArrayToString(params), 'GET');
};

export const completeSaleApi = data => {
  return defaultBody('/api/complete-sale', 'POST', data);
};

function paramArrayToString(params) {
  if (!params || params.length % 2 !== 0) return '';
  let paramString = '';
  for (var i = 0; i < params.length; i += 2) {
    if (i === 0) paramString = '/?' + params[i] + '=' + params[i + 1];
    else paramString += '&' + params[i] + '=' + params[i + 1];
  }
  return paramString;
}

function defaultBody(path, type, data, customHeaders, log) {
  const headers = {
    'Content-Type': 'application/json',
    ...customHeaders,
  };

  return fetch(API_URL + path, {
    method: type,
    headers,
    body: type === 'GET' ? null : JSON.stringify(data),
  })
    .then(getJson(log))
    .then(returnBody(log))
    .catch(error => {
      console.log(path);
      console.error(error);

      if (Number(error.cause) >= 400 && Number(error.cause) < 500) {
        console.log({ error });

        throw new Error(error.message);
      }

      return undefined;
    });
}

export const getJson = log => async res => {
  if (log) {
    console.log('res', res);
  }

  if (res.status >= 400 && res.status < 500) {
    const result = await res.json();
    console.log({ result });

    return {
      error:
        result.error ||
        'Access Unauthorized. Try logging in with a valid account.',
      status: res.status,
      body: null,
    };
  }

  return res.json();
};

export const returnBody = log => async data => {
  if (log) {
    console.log('data', data);
  }

  if (!data || data.error) {
    throw new Error(data.error);
  }

  return data.body;
};

// const customBody = (path, type, data = null, log) => {
//   return window
//     .fetch(path, {
//       method: type,
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//     .then(res => {
//       if (log) console.log('res', res);
//       if (res.status === 200) {
//         return res.json();
//       } else {
//         return { body: null };
//       }
//     })
//     .then(data => {
//       if (log) console.log('data', data);
//       if (!data || data.error) {
//         throw new Error('Create Source error');
//       } else {
//         return data;
//       }
//     });
// };

// import { SortValuesEnum } from "components/CollectionTabs/MarketplaceTab";
// import fetch from "cross-fetch";
// import { API_URL, PLATFORM_SLUG } from "lib/constants";
// import { getAccessTokenFromActiveUser } from "lib/functions/utilities";
// import {
//   HydratedNft,
//   IAffiliate,
//   IAttribute,
//   IBidOrder,
//   ICollection,
//   ICollectionHot,
//   ICollectionFeatured,
//   IDutchInviteList,
//   IInviteList,
//   INft,
//   INotification,
//   IReferral,
//   ISecret,
//   ISellOrder,
//   ITransaction,
//   IUser,
// } from "../types";
// import { ethers } from "ethers";
// import { SortCollectionValuesEnum } from "./collectionsInfiniteQuery";

// class CustomError extends Error {
//   cause?: string;

//   constructor(message?: string, options?: { cause?: string }) {
//     super(message);
//     this.cause = options?.cause;
//   }
// }

// export const fetchUserApi = (params: string[]) => {
//   return defaultBody<IUser>(
//     "/api/fetchUser" + paramArrayToString(params),
//     "GET",
//     null
//   );
// };

// export const validateSuperAffiliate = (superAffiliateCode: string) => {
//   return defaultBody<{ isValid: boolean; address: string }>(
//     `/api/validateSuperAffiliate/${superAffiliateCode}`,
//     "GET",
//     null
//   );
// };

// export const removeBidApi = (data: {
//   token_address: string;
//   token_id: string;
//   user_address: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody("/api/remove-bid", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const deleteUploadedItemsApi = (data) => {
//   return defaultBody("/api/delete-uploaded-items", "POST", data);
// };

// export const removeSaleApi = (data: {
//   token_address: string;
//   token_id: string;
//   user_address: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody("/api/remove-sale", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const createAuctionApi = (data: {
//   value: string;
//   value_decimal: number;
//   title: string;
//   auction_end: Date;
//   duration: number;
//   signature: string;
//   token_address: string;
//   token_id: string;
//   user_address: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody("/api/create-auction", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const getUserNFTsApi = (params: string[]) => {
//   return defaultBody<{ items: any; total_owned: number; moralis_items: any }>(
//     "/api/get-user-nfts" + paramArrayToString(params),
//     "GET"
//   );
// };

// export const getNotifications = (data: {
//   user_address: string;
//   set_read?: boolean;
//   count_only?: boolean;
//   get_all?: boolean;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<INotification[]>("/api/get-notifications", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const getNFTOwnerApi = (params) => {
//   return defaultBody("/api/get-nft-owner" + paramArrayToString(params), "GET");
// };

// export const createBidApi = (data: {
//   bid_order: IBidOrder;
//   user_address: string;
//   avatar_uri: string;
//   username: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();
//   return defaultBody<string>("/api/create-bid", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const createSaleApi = (data: ISellOrder) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody("/api/create-sale", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const createCollection = (data: Partial<ICollection>) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<ICollection>("/api/collection", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const updateUserApi = (data: Partial<IUser>, accessToken: string) => {
//   return defaultBody<IUser & { error?: string }>(
//     "/api/updateUser",
//     "POST",
//     data,
//     { Authorization: `Bearer ${accessToken}` }
//   );
// };

// export const patchUser = (data: Partial<IUser>) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<IUser & { error?: string }>("/api/user", "PATCH", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const searchApi = (params) => {
//   return defaultBody<{ users: any; NFTs: any; collections: any }>(
//     "/api/search" + paramArrayToString(params),
//     "GET"
//   );
// };

// export const getSaleApi = (data) => {
//   return defaultBody<any>("/api/getSale", "POST", data);
// };

// export const sendChat = (data: {
//   token_address: string;
//   message: string;
//   image_url: string;
//   attachment_url: string;
//   username: string;
//   token_id: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody("/api/send-chat", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const getItemBidsApi = (data) => {
//   return defaultBody("/api/get-item-bids", "POST", data);
// };

// export const getLikesApi = (data) => {
//   return defaultBody<{
//     allCollectionLikes: { token_id: string; user_address: string }[];
//   }>("/api/getLikes", "POST", data);
// };

// export const getTransferHistoryApi = (data) => {
//   return defaultBody("/api/get-transfer-history", "POST", data);
// };

// export const getUserLikes = (data) => {
//   return defaultBody("/api/get-user-likes", "POST", data);
// };

// export const getUserCollections = (data: { user_address: string }) => {
//   return defaultBody<ICollection[]>("/api/get-user-collections", "POST", data);
// };

// export const getUserOnSaleNFTs = (data) => {
//   return defaultBody("/api/get-user-on-sale", "POST", data);
// };

// export const setLikesApi = (data: {
//   token_address: string;
//   token_id: string;
//   liked: boolean;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody("/api/setLikes", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const getItemsApi = (data) => {
//   return defaultBody("/api/getItems", "POST", data);
// };

// export const getNumItemsAndOwnersApi = (data) => {
//   return defaultBody("/api/get-num-items-and-owners", "POST", data);
// };

// export const getAuctionsApi = (params) => {
//   return defaultBody("/api/get-auctions" + paramArrayToString(params), "GET");
// };

// export const getCollectionApi = (slugOrAddress: string) => {
//   return defaultBody<ICollection>(
//     `/api/get-collection/${slugOrAddress}`,
//     "GET"
//   );
// };

// export const getCollectionFeatured = (address: string) => {
//   return defaultBody<{
//     primary: ICollectionFeatured;
//     secondary: ICollectionFeatured;
//   }>(`/api/featuredCollections/${address}`, "GET");
// };

// export const fetchHotCollectionsApi = () => {
//   return defaultBody<ICollectionHot[]>("/api/fetchHotCollections", "GET");
// };

// export const fetchFeaturedCollectionsApi = () =>
//   defaultBody<{ primary: ICollection[]; secondary: ICollection[] }>(
//     `/api/fetchFeaturedCollections?platform=${PLATFORM_SLUG}`,
//     "GET"
//   );

// export const fetchPaginatedCollectionsApi = (
//   page: number,
//   sortBy: keyof typeof SortCollectionValuesEnum
// ) => {
//   return defaultBody<ICollectionHot[]>(
//     `/api/fetchPaginatedCollections?page=${page}&sortBy=${sortBy}`,
//     "GET"
//   );
// };

// export const updateCollectionApi = (
//   addressOrSlug: string,
//   data: Partial<ICollection>
// ) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<ICollection>(
//     `/api/collection/${addressOrSlug}`,
//     "PATCH",
//     data,
//     {
//       Authorization: `Bearer ${accessToken}`,
//     }
//   );
// };

// export const addFeaturedCollectionApi = (data: {
//   token_address: string;
//   platform_slug: string;
//   type: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<ICollection>(`/api/featuredCollections`, "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const deleteFeaturedCollectionApi = (data: {
//   token_address: string;
//   platform_slug: string;
//   type: string;
//   creator?: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<ICollection>(`/api/featuredCollections`, "DELETE", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const deleteCollection = (data: {
//   creator: string;
//   name: string;
//   symbol?: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<ICollection>(`/api/collection`, "DELETE", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// /** For the graph part of activity */
// export const fetchTransactionsApi = (params) => {
//   return defaultBody<ITransaction[]>(
//     "/api/fetchTransactions" + paramArrayToString(params),
//     "GET"
//   );
// };

// /** For the history part of activity */
// export const fetchTransactionsHistoryApi = (data) => {
//   return defaultBody<ITransaction[]>(
//     "/api/fetch-transactions-history",
//     "POST",
//     data
//   );
// };

// export const refreshMetadataApi = (data: {
//   token_address: string;
//   token_id: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<string>("/api/refresh-metadata", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const fetchNFTsApi = (
//   data: {
//     token_address: string;
//     user_address: string;
//     id: string;
//     limit: number;
//     offset?: number | null;
//     price?: { min: number; max: number };
//     sale_types?: string[];
//     attributes?: IAttribute[];
//     sortOrder?: keyof typeof SortValuesEnum;
//     owner?: string;
//   },
//   options?: RequestInit
// ) => {
//   return defaultBodyCancellable<HydratedNft[]>({
//     path: "/api/fetch-nfts",
//     type: "POST",
//     data,
//     options,
//   });
// };

// export const fetchNFTsSimpleApi = (data: {
//   token_address: string;
//   ids?: string[];
// }) => {
//   return defaultBody<INft[]>("/api/fetch-nfts-simple", "POST", data);
// };

// export const fetchNFTsByCollectionAndUser = (
//   collectionAddress: string,
//   userAddress: string
// ) => {
//   return defaultBody<Partial<INft>[]>(
//     `/api/nfts/${collectionAddress}/user/${userAddress}`,
//     "GET"
//   );
// };

// export const removeInvalidBidApi = (data: {
//   token_address: string;
//   token_id: string;
//   user_address: string;
//   bidder_address: string;
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody("/api/remove-invalid-bid", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const removeInvalidSaleApi = (data) => {
//   return defaultBody("/api/remove-invalid-sale", "POST", data);
// };

// export const getNumberCollectionItemsApi = (params) => {
//   return defaultBody(
//     "/api/get-number-collection-items" + paramArrayToString(params),
//     "GET"
//   );
// };

// export const getFloorPriceApi = (params) => {
//   return defaultBody<number>(
//     "/api/get-floor-price" + paramArrayToString(params),
//     "GET"
//   );
// };

// export const getDutchInviteList = (
//   collectionAddress: string,
//   listRoot: string
// ) => {
//   return defaultBody<IDutchInviteList>(
//     `/api/collections/${collectionAddress}/dutchinvitelists/${listRoot}`,
//     "GET"
//   );
// };

// export const getInviteList = (collectionAddress: string, root: string) => {
//   return defaultBody<IInviteList>(
//     `/api/collections/${collectionAddress}/invitelists/${root}`,
//     "GET"
//   );
// };

// export const getInviteLists = (collectionAddress: string) => {
//   return defaultBody<IInviteList[]>(
//     `/api/collections/${collectionAddress}/invitelists`,
//     "GET"
//   );
// };

// export const createInviteList = (data: Omit<IInviteList, "cid">) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<string>("/api/invitelist", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const updateDutchInviteList = (
//   collectionAddress: string,
//   root: string,
//   data: Pick<
//     IDutchInviteList,
//     | "currencyAddress"
//     | "token_price"
//     | "reserve_price"
//     | "start_time"
//     | "interval"
//     | "delta"
//     | "limit"
//     | "updated_at"
//   >
// ) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<// Pick<IDutchInviteList, "root" | "limit" | "token_price" | "currencyAddress">
//   IDutchInviteList>(
//     `/api/collections/${collectionAddress}/dutchinvitelists/${root}`,
//     "PATCH",
//     data,
//     {
//       Authorization: `Bearer ${accessToken}`,
//     }
//   );
// };

// export const updateInviteList = (
//   collectionAddress: string,
//   root: string,
//   data: Pick<
//     IInviteList,
//     | "currencyAddress"
//     | "token_price"
//     | "start_time"
//     | "name"
//     | "limit"
//     | "updated_at"
//     | "end"
//     | "unitSize"
//     | "randomize"
//     | "tokenIds"
//     | "decimals"
//     | "isPending"
//   >
// ) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<
//     Pick<
//       IInviteList,
//       "root" | "limit" | "token_price" | "cid" | "currencyAddress"
//     >
//   >(
//     `/api/collections/${collectionAddress}/invitelists/${root}`,
//     "PATCH",
//     data,
//     {
//       Authorization: `Bearer ${accessToken}`,
//     }
//   );
// };

// export const deleteInviteList = (collectionAddress: string, root: string) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<{ ok: number }>(
//     `/api/collections/${collectionAddress}/invitelists/${root}`,
//     "DELETE",
//     {},
//     {
//       Authorization: `Bearer ${accessToken}`,
//     }
//   );
// };

// export const createERC1155NFTs = (data: {
//   tokenAddress: string;
//   baseURI: string;
//   maxSupply?: number[];
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<any>("/api/nfts/ERC1155", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const createAffiliates = (data: {
//   collectionAddress: string;
//   affiliates: string[];
// }) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<{
//     duplicateAddresses?: string[];
//     insertedIds: string[];
//     numberInserted: number;
//     newAffiliates: IAffiliate[];
//   }>("/api/affiliate", "POST", data, {
//     Authorization: `Bearer ${accessToken}`,
//   });
// };

// export const getAffiliateData = (params: string[]) => {
//   return defaultBody<{ address: string; signature: string; ens?: string }>(
//     "/api/affiliate" + paramArrayToString(params),
//     "GET"
//   );
// };

// export const fetchSecret = (collectionAddress: string, tokenId: number) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<{ secret: string }>(
//     `/api/secrets/${collectionAddress}/${tokenId}`,
//     "GET",
//     {},
//     {
//       Authorization: `Bearer ${accessToken}`,
//     }
//   );
// };

// export const fetchSecrets = (collectionAddress: string) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<{ secrets: ISecret[] }>(
//     `/api/secrets/${collectionAddress}`,
//     "GET",
//     {},
//     {
//       Authorization: `Bearer ${accessToken}`,
//     }
//   );
// };

// export const hasSecret = (collectionAddress: string, tokensArray: number[]) => {
//   let tokensArrString = tokensArray.map((t) => `tokens[]=${t}`).join("&");

//   return defaultBody<Record<number, boolean>>(
//     `/api/hasSecret/${collectionAddress}?${tokensArrString}`,
//     "GET"
//   );
// };

// export const updateSecret = (
//   collectionAddress: string,
//   tokenId: string,
//   data: { secret: string }
// ) => {
//   const accessToken = getAccessTokenFromActiveUser();

//   return defaultBody<ISecret>(
//     `/api/secrets/${collectionAddress}/${tokenId}`,
//     "PATCH",
//     data,
//     {
//       Authorization: `Bearer ${accessToken}`,
//     }
//   );
// };

// export const fetchEtherscanTransfers = (path, data) => {
//   return customBody(path, "GET", null, false);
// };

// export const getEthToUsdApi = (path) => {
//   return customBody(path, "GET", null, false);
// };

// function defaultBodyCancellable<T>({
//   path,
//   type,
//   data = null,
//   customHeaders,
//   log = false,
//   options,
// }: {
//   path: string;
//   type: string;
//   data?: any;
//   customHeaders?: Record<string, string>;
//   log?: boolean;
//   options?: RequestInit;
// }): Promise<T | undefined> {
//   const headers = {
//     "Content-Type": "application/json",
//     ...customHeaders,
//   };

//   // console.log({
//   //   options: {
//   //     method: type,
//   //     headers,
//   //     body: type === "GET" ? null : JSON.stringify(data),
//   //     ...options,
//   //   },
//   // });

//   return fetch(API_URL + path, {
//     method: type,
//     headers,
//     body: type === "GET" ? null : JSON.stringify(data),
//     ...options,
//   })
//     .then(getJson(true))
//     .then(returnBody<T>(true))
//     .catch((error) => {
//       console.log(path);
//       console.error(error);
//       return undefined;
//     });
// }

// export function getEditCollectionLink(slug?: string) {
//   if (!slug) {
//     return "#";
//   }
//   return `/${slug}/edit`;
// }
